<mat-nav-list>
    <a mat-list-item (click)="sendId('trainings')" class="nav-caption text-sm w-full" (click)="onSidenavClose()">
        ENTRENAMIENTOS
    </a>
    <hr class="w-10/12 mx-auto">
    <a mat-list-item routerLink="/empresa" class="nav-caption text-sm w-full" (click)="onSidenavClose()">
        EMPRESA
    </a>
    <hr class="w-10/12 mx-auto">
    <a mat-list-item routerLink="/ONG" class="nav-caption text-sm w-full" (click)="onSidenavClose()">
        ONG
    </a>
    <hr class="w-10/12 mx-auto">
    <a mat-list-item routerLink="/us" class="nav-caption text-sm w-full" (click)="onSidenavClose()">
        NOSOTROS
    </a>
    <!-- <hr>
    <a mat-list-item href="https://folcademy.folclass.com/auth/login/platform" target="_blank" (click)="onSidenavClose()">
        <span class="nav-caption text-2xl">Plataforma</span>
    </a> -->
</mat-nav-list>