import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './core/layouts/layouts.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoutesModule } from './core/routes/routes.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenantInterceptor } from 'tenant.interceptor';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutsModule,
    BrowserAnimationsModule,
    RoutesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
