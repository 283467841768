/* tslint:disable: ordered-imports*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';


/* Modules */
// import { AppCommonModule } from '@common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as layoutsComponents from './components';

/* Containers */
import * as layoutsContainers from './containers';

/* Guards */
import * as layoutsGuards from './guards';

/* Services */
import * as layoutsServices from './services';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatListModule } from '@angular/material/list';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        // AppCommonModule,
        // NavigationModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatSelectModule,
        MatListModule
    ],
    providers: [...layoutsServices.services, ...layoutsGuards.guards],
    declarations: [...layoutsContainers.containers, ...layoutsComponents.components, SidenavComponent],
    exports: [...layoutsContainers.containers, ...layoutsComponents.components],
})
export class LayoutsModule { }
