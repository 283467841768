import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  url: string;

  constructor(
    private http: HttpClient,
  ) {
    this.url = environment.apiUrl;
   }

  parseToHttpParams = (params:any): HttpParams => {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(p => httpParams = httpParams.append(p, typeof params[p] === 'string' ? params[p] : JSON.stringify(params[p])));
    return httpParams; };

  getCourses(param: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const httpParams = this.parseToHttpParams(param);
    return this.http.get<any>(this.url + '/courses', {
      headers,
      params: httpParams
    })
  }
}
