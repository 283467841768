import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  url: string;

  constructor() {
    this.url = environment.apiUrl;
  }

  getTenant(){
   return "folcademy";
  }
}