<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title> ¿Los entrenamientos son virtuales? </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      Sí, los entrenamientos son 100% online.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title>
        ¿Qué pasa si no puedo ver las clases en los horarios establecidos?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      Las masterclasses y workshops quedan grabados en la plataforma para que
      los veas luego. Están disponibles 24 horas luego del encuentro. No es
      obligatorio hacer los entrenamientos de manera sincrónica aunque sí
      recomendable. Tendrás acceso al material de tu entrenamiento por un año
      desde la fecha de finalización.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title>
        ¿Qué pasa si no tengo los conocimientos básicos requeridos?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      Te recomendamos no inscribirte en los entrenamientos que no cumplas con
      los requisitos básicos Si no sabes con cual comenzar contáctanos para que
      te asesoremos.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title>
        ¿Cuáles son los requisitos para obtener mi certificado?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      El único requisito es el 100% de los desafíos aprobados. El plazo final de
      entrega de desafíos se te comunicará por mail y discord una vez comenzado
      el entrenamiento. Podrás descargar tu certificado una vez finalizado el
      entrenamiento.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title>
        ¿Qué sucede si quiero cambiarme de entrenamiento o me arrepiento de
        hacerlo?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      Podrás cambiarte de entrenamiento o solicitar la devolución de tu dinero
      según lo establecido en
      <a class="c-pur sub" href="/tyc"
        >”Términos y Condiciones”</a
      >
    </p>
  </mat-expansion-panel>
</mat-accordion>
