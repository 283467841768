<!-- <main class="main">
  <section class="flex justify-center section-2 h-full w-full pt-10 w-screen">
    <div class="lg:w-4/5 flex flex-col justify-around h-full w-screen">
      <div
        class="w-10/12 md:w-full flex flex-col items-center justify-center mx-auto md:mx-0 mb-5 md:mb-0 text-xs md:text-base break-words h-1/6 md:h-1/6 w-screen"
      >
        <span
          class="title-section mb-2 md:mb-4 text-2xl font-semibold md:text-5xl text-center w-screen"
        >
          Términos y condiciones
        </span>
        <div class="sep">
          <span class="text-sm md:text-xl subtitle1"> Definiciones </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              <strong>El Sitio:</strong> Se refiere al sitio web
              <a class="c-pur" href="https://folcademy.com/home"
                >“www.folcademy.com”</a
              >
              y
              <a class="c-pur" href="https://folcademy.folclass.com/"
                >”folcademy.folclass.com”</a
              >
              , donde se promociona, comercializa y desarrolla el servicio
              formativo de entrenamientos de roles de la industria tech,
              brindado por la empresa Folcode S.R.L. -en adelante Folcademy -,
              CUIT 30-71542798-9, inscripta bajo el número de legajo 5544 del
              Registro Público de Comercio de la provincia de San Juan,
              Argentina. Por este motivo es que estos términos y condiciones se
              referirán indistintamente al sitio, y/o Folocode SRL, y/o Folcode,
              y/o la empresa y/o a Folcademy.
            </p>

            <p class="text-sm md:text-xl color-p">
              <strong>Escuela de entrenamientos/plataforma:</strong> Se refiere
              a “El Sitio”. Es allí donde los usuarios llevarán a cabo el
              cursado de los entrenamientos de roles comercializados por
              Folcademy.
            </p>

            <p class="text-sm md:text-xl color-p">
              <strong>Entrenamientos: </strong> referencia indistintamente a
              cualquiera de los cursos y/o programas de enseñanza ofrecidos por
              Folcademy.Usuario: En general se atribuye la calidad de usuario a
              los/las visitantes del sitio que utilicen y/o contraten los
              servicios formativos. TyC: Se refiere a los Términos y Condiciones
              que regulan el uso y los servicios prestados mediante el portal de
              Internet (en adelante, el sitio) que se pone a disposición de
              los/las usuarios de Internet.TyC de inscripción: Son los Términos
              y Condiciones todo el proceso de inscripción y cursado a los
              entrenamientos que Folcademy presta mediante la Escuela de
              entrenamientos.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            1. Condiciones de uso - Aceptación de terminos y condiciones
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              El uso del sitio estará sujeto a los siguientes términos y
              condiciones de uso, en adelante los "Términos y Condiciones", que
              deberán ser cumplidos por los usuarios del mismo, en adelante e
              indistintamente "usted", y/o “cliente” y/o "el usuario". La
              utilización del sitio atribuye al visitante, la condición de
              usuario del sitio e implica su aceptación plena y sin reservas de
              todas y cada una de las disposiciones incluidas en estos “Términos
              y Condiciones Generales”, en la versión publicada por
              www.folcademy.com en el momento mismo en que el usuario acceda al
              sitio. En consecuencia, el usuario debe leer atentamente los
              presentes “Términos y Condiciones Generales” en cada una de las
              ocasiones en que se proponga utilizar el sitio, ya que esos TyC
              pueden sufrir modificaciones.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            2. Cambios en los términos y condiciones
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Folcademy revisa periódicamente los Términos y Condiciones,
              pudiendo introducir modificaciones, las que producirán efectos
              desde el momento en que son incorporadas al texto. La versión
              modificada de los Términos y Condiciones será publicada en el
              sitio y usted quedará notificado/a en su próximo ingreso en
              carácter de usuario. Si usted no está de acuerdo con los Términos
              y Condiciones modificados, usted deberá dejar de utilizar el
              servicio. El uso del servicio después de que los Términos y
              Condiciones modificados hayan sido publicados, constituirá su
              aceptación de los Términos y Condiciones modificados.Así mismo
              Folcademy se reserva el derecho a suspender temporalmente, sin
              necesidad de previo aviso, el acceso a este sitio para realizar
              operaciones de mantenimiento, actualización, mejora o de
              reparación del sitio.Folcademy tiene la capacidad de emitir
              advertencias, suspensiones temporales y cancelaciones permanentes
              (baja) de los usuarios registrados por infracciones a los
              presentes Términos y Condiciones, así como a cualquier aviso,
              reglamento de uso e instrucción puestos en conocimiento del
              usuario por Folcademy. En ese sentido, Folcademy se reserva el
              criterio con respecto a cuándo y cómo imponer advertencias,
              sanciones y/o acciones disciplinarias. La severidad de un
              incumplimiento y el número de ocurrencias se toman en cuenta en
              las decisiones, pero éstas no son las únicas medidas utilizadas.En
              caso de incumplimiento de los presentes Términos y Condiciones
              Generales, Folcademy tendrá derecho a dar de baja provisionalmente
              al usuario registrado del sitio, previa advertencia de amenaza de
              ello. La advertencia de amenaza de baja resultará innecesaria
              cuando concurran circunstancias especiales que justifiquen la baja
              inmediata del usuario registrado.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            3. Información contenida en el sitio
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Folcademy recaudará información sobre usted o su empresa solamente
              cuando considere que es razonablemente necesario.Nosotros
              recaudamos información privada de las siguientes fuentes:
              Información recibida en solicitudes, encuestas, inscripciones en
              línea, u otras formas, Información que recibimos por parte de
              nuestros clientes y otras instituciones, incluyendo información
              relacionada a su historial crediticio. Esta información será
              referida como “información del usuario”.El usuario a través de las
              distintas áreas que forman parte del sitio puede visitar y obtener
              información. Nuestros clientes y usuarios no retienen, difunden,
              almacenan ni utilizan información para ningún propósito diferente
              a los enunciados. La información recibida por Folcademy nunca es
              vendida, cedida o alquilada a otras empresas.Folcademy procura que
              la información contenida en el sitio sea completa, exacta y
              actualizada. Sin embargo, Folcademy, no está en condiciones de
              verificar la exactitud, integridad o vigencia de toda la
              información ofrecida, por lo cual en ningún caso garantiza la
              exactitud, integridad o vigencia de la información contenida en el
              sitio. Tampoco garantiza que los aspectos funcionales del sitio,
              se encuentren libres de error, o que el sitio, o el servidor que
              lo hace disponible al usuario se encuentren libres de virus o de
              cualquier otro componente de carácter dañino, ni garantiza que los
              archivos que se encuentren disponibles para descargar se
              encuentren libres de infección, virus u otras manifestaciones de
              códigos contaminados o propiedades destructivas. Folcademy, no es
              responsable del daño directo, indirecto, previsto o imprevisto, o
              cualquier otro, que provoque el uso del sitio, del material o de
              los servicios ofrecidos en este sitio, al sistema de tratamiento
              de la información del usuario, al usuario o a terceros. El usuario
              reconoce que las medidas de seguridad en Internet no son
              inexpugnables.Procuramos que toda la información contenida en el
              sitio, sea verificada, y en caso de que se trate de información
              directamente suministrada por los usuarios, no se garantiza la
              exactitud, integridad, confiabilidad o vigencia del material,
              conexiones, o del resultado que se obtenga del uso del sitio y de
              sus servicios.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1"> 4. Objeto </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              A través del sitio, Folcademy tiene por objeto promocionar,
              comercializar de manera absolutamente privada y brindar los
              servicios que comprenden la academia de experiencias formativas
              mediante entrenamientos de roles de la industria tech. A tales
              fines, a través del sitio, se buscará poner en conocimiento del
              usuario, los servicios que comprenden su actividad, como así
              también otorgarle publicidad a la marca Folcademy, Folclass y/o
              Folcamp, todo ello a los fines de que el usuario pueda tener
              acceso a dicha información, como así también a las
              “entrenamientos” promocionadas y comercializadas mediante la
              misma, siempre en los términos y condiciones fijados en el
              presente documento.Reconoce el usuario que Folcademy no es una
              entidad educativa reconocida como tal por el Ministerio de
              Educación de la Provincia de San Juan, sino que tal como
              anteriormente se expresa, sus actividades formativas son netamente
              privadas. Esto no obsta a que determinados entrenamientos puedan
              contar con el aval del Ministerio de Educación de la provincia de
              San Juan, lo que se aclarará en el caso de que así suceda.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            5. Condiciones de acceso y utilización del sitio
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              5.1. Carácter gratuito del acceso y utilización del SitioEl acceso
              al sitio por parte de los usuarios tiene carácter gratuito.
            </p>

            <p class="text-sm md:text-xl color-p">5.2. Registro de Usuario</p>

            <p class="text-sm md:text-xl color-p">
              El usuario acepta, por el hecho de registrarse como tal en el
              sitio, el derecho de Folcademy de comunicarse por todas las vías y
              medios posibles, incluidos los electrónicos, hasta tanto soliciten
              la cancelación de su cuenta en el sitio, o hasta donde la
              legislación aplicable sobre la materia lo permita.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            6. Responsabilidades del usuario
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              El usuario se compromete a utilizar el servicio de conformidad con
              la legislación vigente, la moral, las buenas costumbres y el orden
              público, así como a abstenerse de utilizar el servicio con fines o
              efectos ilícitos, prohibidos en los presentes TyC, y/o lesivos de
              los derechos e intereses de terceros, y/o que de cualquier forma
              puedan dañar, inutilizar, sobrecargar o deteriorar los servicios,
              los equipos informáticos de otros usuarios o de otros usuarios de
              Internet (hardware y software), así como los documentos, archivos
              y toda clase de contenidos almacenados en sus equipos informáticos
              (hacking), o impedir la normal utilización o disfrute de dichos
              servicios, equipos informáticos y documentos, archivos y
              contenidos por parte de los demás usuarios y de otros usuarios.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            7. No licencia de uso de propiedad intelectual
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Todas las marcas, nombres comerciales o signos distintivos de
              cualquier clase que aparecen en el sitio son propiedad de Folcode
              S.R.L., o de sus respectivos titulares, sin que pueda entenderse
              que el uso o acceso al sitio y/o a los servicios atribuya al
              usuario derecho alguno sobre las citadas marcas, nombres
              comerciales y/o signos distintivos. En todos los casos, el uso de
              marcas, nombres comerciales y/o signos distintivos de terceros en
              el sitio son únicamente con fines informativos para los usuarios
              del sitio y los consumidores en general.Asimismo los contenidos,
              imágenes, reseñas, textos y otros derechos de propiedad
              intelectual que aparecen en el sitio son de titularidad de Folcode
              o de sus respectivos titulares y/o autores, sin que puedan
              entenderse cedidos en su derecho de uso, reproducción,
              distribución, comercialización, modificación o explotación
              comercial en cualquier forma, al usuario. En virtud de lo
              establecido en los presentes “Términos y Condiciones Generales”,
              ninguno de los derechos existentes o que puedan existir en el
              futuro sobre dichos contenidos, imágenes, reseñas, textos y otros
              Derechos de Propiedad Intelectual, podrá ser utilizado por el
              usuario más allá de lo estrictamente necesario para el correcto
              uso del sitio y de los servicios ofrecidos, y siempre bajo los
              principios de buena fe y uso honesto de los mismos.El usuario no
              puede descargar, copiar, reproducir, volver a publicar, enviar por
              correo ordinario, transmitir, almacenar, vender o distribuir las
              obras y otros derechos de propiedad intelectual sin el permiso
              escrito previo del sitio o del titular del derecho de propiedad
              intelectual, salvo que se trate de descargar una copia en
              cualquier ordenador únicamente para uso personal, doméstico y no
              comercial, siempre que se mantengan intactas las advertencias
              sobre derechos de propiedad intelectual y cualquier otra
              advertencia de propiedad. Está prohibido modificar el contenido o
              usar parte del contenido o la información proporcionada para otro
              propósito que no sea el establecido en los presentes Términos y
              Condiciones.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            8. Popiedad de los bienes y/o servicios ofertados
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              En relación a lo fijado en el punto 4 del presente, el objeto del
              sitio es otorgar al usuario toda la información relacionada con la
              promoción, comercialización y desarrollo de la academia de
              experiencias formativas y entrenamientos de roles de la industria
              tech.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            9. Exclusión de garantías y de responsabilidad
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              9.1. Exclusión de garantías y de responsabilidad por el
              funcionamiento del sitio.El sitio no garantiza la privacidad y
              seguridad de la utilización del sitio y de sus servicios ofrecidos
              y, en particular, no garantiza que terceros no autorizados no
              puedan tener conocimiento de la clase, condiciones,
              características y circunstancias del uso que los Usuarios hacen
              del sitio y de los servicios allí contenidos.
            </p>

            <p class="text-sm md:text-xl color-p">
              9.2. Exclusión de garantías y de responsabilidad por los
              contenidos
            </p>

            <p class="text-sm md:text-xl color-p">
              9.2.1. Calidad Folcode no controla, monitorea, ni garantiza la
              ausencia de virus ni de otros elementos maliciosos en los
              contenidos que puedan producir alteraciones en su sistema
              informático (software y hardware) o en los documentos electrónicos
              almacenados en su sistema informático.
            </p>

            <p class="text-sm md:text-xl color-p">
              9.2.2. Licitud, fiabilidad y utilidadFolcode no garantiza la
              licitud, fiabilidad, utilidad, veracidad, exactitud, legalidad,
              exhaustividad y/o actualidad de los Contenidos propios, o los
              proporcionados por otros Usuarios.
            </p>

            <p class="text-sm md:text-xl color-p">
              9.3. Exclusión de garantías y de responsabilidad por la
              información, contenidos y servicios alojados fuera del sitio.El
              sitio puede poner a disposición de los usuarios dispositivos
              técnicos de enlace (tales como, entre otros, links, banners,
              botones), directorios y herramientas de búsqueda que permiten a
              los usuarios acceder a sitios web pertenecientes y/o gestionados
              por terceros (en adelante, “Sitios Enlazados”). La instalación de
              estos enlaces, directorios y herramientas de búsqueda en el sitio
              tiene por único objeto facilitar a los usuarios la búsqueda y
              acceso a la información, contenidos y servicios disponibles en
              Internet.Los resultados de herramientas de búsqueda son
              proporcionados directamente por terceros y son consecuencia del
              funcionamiento automático de mecanismos técnicos, por lo que
              Folcode no puede controlar y no controla tales resultados y, en
              particular, que entre ellos aparezcan sitios de Internet cuyos
              contenidos puedan resultar ilícitos, contrarios a la moral o a las
              buenas costumbres o considerados inapropiados por otros motivos.
              En el supuesto de que un usuario considere que en alguno de los
              sitios incluidos en los resultados de la búsqueda o enlaces de
              terceros se presenta alguna actividad o información ilícita y esté
              interesado en solicitar la supresión del enlace, debe poner en
              conocimiento a Folcode de tal situación mediante las vías de
              contacto existentes el sitio.Folcode no ofrece ni comercializa por
              sí ni por medio de terceros la información, contenidos y servicios
              disponibles en los sitios enlazados, ni los controla previamente,
              aprueba, recomienda, vigila ni hace propios. El usuario, por
              tanto, debe extremar la prudencia en la valoración y utilización
              de la información, contenidos y servicios existentes en los sitios
              enlazados.El Usuario reconoce y acepta que Folcode no es parte en
              ningún contacto, comunicación, intercambio de información,
              promesas, negociaciones, ofertas y/u operaciones que pueda tener
              con otros usuarios y terceros, incluidos anunciantes del sitio.
              Folcode no puede verificar la identidad o datos personales
              ingresados por los usuarios.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            10. Protección de datos personales
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Para utilizar los servicios ofrecidos por Folcode, en algunos
              casos los usuarios deberán facilitar determinados datos de
              carácter personal.Desde el momento de su ingreso y/o utilización
              al sitio el usuario consiente expresamente a Folcode para el
              tratamiento de los datos personales que por él sean facilitados o
              que se faciliten a través de su ingreso al sitio o por cualquier
              medio. Asimismo, el usuario consiente expresamente que Folcode
              pueda ceder dichos datos personales a terceros.Los datos
              personales serán incorporados a la base de datos de clientes de
              titularidad de Folcode. Sus datos serán utilizados en la gestión
              administrativa y comercial de la petición del usuario y de una
              eventual relación contractual con Folcode.Su información personal
              se procesa y almacena en servidores o medios magnéticos que
              mantienen altos estándares de seguridad y protección tanto física
              como tecnológica. Una vez registrados los datos del usuario,
              Folcode no venderá, alquilará o compartirá la información
              personal, excepto en las formas establecidas en sus políticas, y
              en los límites permitidos por la normativa vigente. Puede suceder
              que en virtud de órdenes judiciales, o de regulaciones legales,
              Folcode se vea obligado a revelar información a las autoridades o
              terceras partes bajo ciertas circunstancias, o bien en casos que
              terceras partes puedan interceptar o acceder a cierta información
              o transmisiones de datos, en cuyo caso Folcode no responderá por
              la información que sea revelada.El Usuario presta su
              consentimiento para que se puedan transferir sus datos en los
              términos y condiciones anteriormente indicados, a otras entidades,
              incluso con destino a un país extranjero.Los datos se mantendrán
              en la base de datos de Folcode mientras se consideren útiles para
              enviarle al usuario por cualquier medio o soporte información de
              Folcode y de terceros que considere pertinente, así como para
              realizar encuestas, estadísticas, etc.El Usuario autoriza a
              Folcode a conservar sus datos para que esta información forme
              parte de la Big Data de la empresa, y para que el usuario pueda
              recibir información publicitaria y ofertas comerciales.El Usuario
              puede ejercer los derechos de acceso, rectificación, oposición y
              cancelación de los datos personales, los que se podrá ejercer
              mediante la opción de contacto que ponemos a su disposición.El
              usuario responde de la veracidad de los datos facilitados por él,
              reservándose Folcode el derecho a excluirlo, imponer las sanciones
              o realizar las denuncias respectivas, en caso de constatar la
              falsedad o inexactitud de los mismos.El usuario, por el simple
              hecho de registrarse y/o utilizar el sitio, acepta que éste le
              envíe toda clase de anuncios y publicidad (incluido, pero no
              limitado a correos electrónicos comerciales, folletos, cartas,
              etc.) a su dirección física y/o virtual declarada, con las
              novedades, ofertas y promociones de los servicios que brinda. En
              cualquier caso, si el usuario no desea seguir recibiendo tales
              comunicaciones, podrá revocar la autorización de recepción
              otorgada mediante la presente, para lo cual deberá comunicarse con
              Folcode por medio del sitio, a fin de ser eliminado de su base de
              datos.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            11. Requerimientos del sistema
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Al utilizar el sitio, el usuario acepta que el hardware, software
              y acceso a Internet juega un papel crucial en su experiencia de
              navegación. En ese sentido, el Usuario acepta que Folcode no es
              responsable por ningún inconveniente relacionado al hardware,
              software o acceso a Internet del propio usuario, y por tanto exime
              de responsabilidad a Folcode por dichos problemas.El usuario es el
              único y completo responsable por los gastos de conexión a
              Internet, así como por todo el equipo necesario para ello, su
              revisión, reparación o corrección y cualquier otro costo o gastos
              por el mantenimiento de los mismos.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            12. Corte del servidor
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Existirán periodos regulares de mantenimiento del servidor así
              como de mantenimiento del sitio. Folcode hará su mejor esfuerzo
              para reducir en la mayor medida de lo posible el tiempo de
              inactividad del servidor. Sin embargo, Folcode no es responsable
              por ningún daño sufrido por el usuario como resultado del
              mantenimiento u otros tiempos de inactividad del servidor o el
              sitio. El usuario acuerda eximir de responsabilidad a Folcode por
              cualquier daño sufrido, incluido el lucro cesante y daño emergente
              que pueda ocasionarse por tales hechos.Folcode no será responsable
              por ningún daño o perjuicio sufrido por el usuario debido a causas
              imprevistas o causas fuera del control de Folcode, tales como
              actos de naturaleza, guerra, terrorismo, motines, embargos, actos
              de autoridades civiles o militares, incendio, inundaciones,
              accidentes, huelgas o escasez de facilidades de transporte,
              combustible, energía, trabajo y materiales.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            13. Modificación del sitio web
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Folcode se reserva el derecho a eliminar o modificar cualquier
              parte del sitio, en cualquier momento y sin previo aviso.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            14. Legislación y juridicción aplicable
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Los presentes “Términos y Condiciones” se rigen en todos y cada
              uno de sus extremos por las leyes de la República Argentina.
              Folcode y el usuario, con renuncia expresa a cualquier otro fuero,
              se someten al de los Juzgados y Tribunales de San Juan, Argentina.
            </p>
          </div>
        </div>




        <span
          class="title-section mb-2 md:mb-4 text-2xl font-semibold md:text-5xl text-center mt-16"
        >
          Términos y condiciones de inscripción
        </span>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            *INSCRIPCIONES: Proceso de inscripción
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              La inscripción a los entrenamientos deberá efectuarse vía web en
              <a
                class="c-pur"
                href="https://folcademy.folclass.com/auth/login/platform"
                >https://folcademy.folclass.com/platform.</a
              >
              El usuario deberá, crear un usuario, completar el registro
              correspondiente y seleccionar el/los entrenamientos a desarrollar
              teniendo en cuenta las fechas y horarios que se ajusten a su
              responsabilidad en tanto haya vacantes, efectuar el pago de
              acuerdo a las instrucciones que surjan de la plataforma.
            </p>
            <p class="text-sm md:text-xl color-p">
              Una vez completada la inscripción, recibirá un mail de bienvenida
              con el detalle de la inscripción e instrucciones necesarias para
              continuar.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1"> *Cupos limitados </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Los cupos a los entrenamientos son limitados. El cupo, se confirma
              y se reserva únicamente habiendo realizado el pago del mismo por
              cualquiera de los medios habilitados a tal fin. El cupo del curso
              se cerrará cuando se completen las vacantes del mismo.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Reprogramación y/o Cancelación de entrenamientos
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Folcademy se reserva el derecho de reprogramar y/o cancelar los
              entrenamientos según lo disponga. En el caso de cancelaciones o
              reprogramaciones, los usuarios que ya hayan abonado el
              entrenamiento, podrán optar por utilizar el saldo en otro curso de
              su interés, transferirlo a otra persona u optar por el reembolso.
              <br />
              Asimismo, Folcademy se reserva el derecho a modificar la fecha de
              inicio del curso con previo aviso a los estudiantes por falta de
              asignación profesor o modificaciones en el contenido. <br />
              Este tipo de cambios se avisaran por cualquiera de las vías de
              comunicación que tiene Folcademy con sus usuarios.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Cambios de entrenamientos. Opciones en relación a los pagos
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              El usuario, podrá solicitar el cambio del entrenamiento en el que
              se inscriba, según las siguientes condiciones:
            </p>
            <p class="text-sm md:text-xl color-p">
              A. Si la solicitud la realiza antes del inicio del entrenamiento
              originario: Hasta tres (3) días hábiles antes de la fecha de
              inicio, se puede solicitar el cambio, siempre y cuando aún haya
              disponibilidad en el nuevo entrenamiento solicitado.
            </p>
            <p class="text-sm md:text-xl color-p">
              B. Si la solicitud de cambio se realiza una vez iniciado el
              entrenamiento originario: Sólo en caso de que al momento de
              realizar la solicitud se cumplan las siguientes condiciones:
            </p>
            <p class="text-sm md:text-xl color-p">
              1. La solicitud de cambio se realice dentro de los primeros cinco
              (5) días hábiles de iniciado el entrenamiento
            </p>

            <p class="text-sm md:text-xl color-p">
              2. El entrenamiento al que desee cambiarse tenga vacantes de cupos
              y mentores suficientes.
            </p>

            <p class="text-sm md:text-xl color-p">
              Cumplidas estas dos condiciones, el cambio se hará efectivo dentro
              de los tres (3) días hábiles posteriores a que Folcademy reciba la
              solicitud y verifique la disponibilidad de cursos.
            </p>
            <p class="text-sm md:text-xl color-p">
              En ambos casos (A y B), si el entrenamiento por el que el usuario
              opta cambiar tiene un valor mayor al del entrenamiento
              originalmente contratado, el usuario deberá abonar la diferencia.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Desistimiento del Usuario
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              El usuario que decida abandonar el entrenamiento y no opte por la
              opción de “cambio de entrenamientos”, sólo podrá solicitar la
              devolución del dinero si desiste del entrenamiento dentro de la
              primera semana de iniciado el cursado de la misma. Vencido dicho
              plazo, el dinero no es reembolsable.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Condiciones de pago
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              El usuario que decida abandonar el entrenamiento y no opte por la
              opción de “cambio de entrenamientos”, sólo podrá solicitar la
              devolución del dinero si desiste del entrenamiento dentro de la
              primera semana de iniciado el cursado de la misma. Vencido dicho
              plazo, el dinero no es reembolsable.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Entrenamientos: formas y modalidades
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              La duración y modalidad del cursado de los entrenamientos, como
              así también el cronograma, tareas y/o actividades a desarrollar
              cada semana, entregas, trabajos, evaluaciones y feedback de los
              mentores/profesores y/o cualquier otro aspecto en relación a las
              formas del cursado de los entrenamientos se especificarán por
              Folcademy en su
              <a class="c-pur" href="https://folcademy.folclass.com/"
                >sitio web</a
              >
              en los apartados “Acerca del entrenamiento” y “Cronograma” que se
              encuentran dentro de cada entrenamiento.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Pagos de cada entrenamiento
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Para cada caso concreto de cada entrenamiento puntual, es que el
              sitio web de publicará todo lo referido a aspectos que, a modo
              enunciativo pero no limitativo, se refiera a: modalidad de pagos,
              facturaciones, entidades bancarias admitidas, pago de comisiones
              bancarias, impuestos por tales operaciones, descuentos,
              promociones, plazos para efectuar los pagos, intereses, modo de
              reclamo de esos montos, devolución, etc.
            </p>
            <p class="text-sm md:text-xl color-p">
              Todo esto, siempre que no se encuentren tales aspectos ya
              desarrollados en el presente TyC.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Material de estudio
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              El material de estudio que recibe el usuario de acuerdo al
              entrenamiento contratado, estará disponible en la plataforma de
              folclass y quedará a disposición del usuario por un año contados a
              partir de la finalización del entrenamiento.
            </p>
          </div>
        </div>

        <div class="sep">
          <span class="text-sm md:text-xl subtitle1">
            Certificados de finalización
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Todos los participantes de los entrenamientos, al finalizar el
              mismo podrán acceder a un “Certificado de finalización de
              entrenamiento”, el cual quedará a disposición de cada participante
              en
              <a class="c-pur" href="https://folcademy.folclass.com/"
                >“el sitio”</a
              >, para que lo descarguen. Asimismo, estos podrán solicitarlo a
              Folcademy hasta un año posterior a la finalización del curso.
              Cumplido este plazo, el participante nada podrá reclamar a
              Folcademy
            </p>
          </div>
        </div>

        <div class="sep sep2">
          <span class="text-sm md:text-xl subtitle1">
            Legislación y Jurisdicción aplicable
          </span>
          <div class="text1">
            <p class="text-sm md:text-xl color-p">
              Los presentes “Términos y Condiciones de Inscripción” se rigen en
              todos y cada uno de sus extremos por las leyes de la República
              Argentina.Folcode y el usuario, con renuncia expresa a cualquier
              otro fuero, se someten al de los Juzgados y Tribunales de San
              Juan, Argentina.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</main> -->

<main class="main bg-neutro">
  <section
    class="flex justify-center section-2 h-full w-full pt-10 mt-24 w-screen"
  >
    <div class="lg:w-4/5 flex flex-col justify-around h-full w-screen padd1">
      <div class="mb-3 lg:mb-6 text-center">
        <span
          class="title-section mb-2 md:mb-4 text-2xl font-semibold md:text-5xl w-screen"
        >
          Términos y condiciones
        </span>
      </div>

      <div class="mt-14">
        <span class="text-sm md:text-xl subtitle1"> Definiciones </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            <strong>El Sitio:</strong> Se refiere al sitio web
            <a class="c-pur" href="https://folcademy.com/home"
              >“www.folcademy.com”</a
            >
            y
            <a class="c-pur" href="https://folcademy.folclass.com/"
              >”folcademy.folclass.com”</a
            >
            , donde se promociona, comercializa y desarrolla el servicio
            formativo de entrenamientos de roles de la industria tech, brindado
            por la empresa Folcode S.R.L. -en adelante Folcademy -, CUIT
            30-71542798-9, inscripta bajo el número de legajo 5544 del Registro
            Público de Comercio de la provincia de San Juan, Argentina. Por este
            motivo, es que estos términos y condiciones se referirán
            indistintamente al sitio, y/o Folocode SRL, y/o Folcode, y/o la
            empresa y/o a Folcademy.
          </p>

          <p class="text-sm md:text-xl color-p">
            <strong>Escuela de entrenamientos/plataforma:</strong> Se refiere a
            “El Sitio”. Es allí donde los usuarios llevarán a cabo el cursado de
            los entrenamientos de roles comercializados por Folcademy.
          </p>

          <p class="text-sm md:text-xl color-p">
            <strong>Entrenamientos: </strong> Hace referencia indistintamente a
            cualquiera de los cursos y/o programas de enseñanza ofrecidos por
            Folcademy. Usuario: En general se atribuye la calidad de usuario a
            los/las visitantes del sitio que utilicen y/o contraten los
            servicios formativos. TyC: Se refiere a los Términos y Condiciones
            que regulan el uso y los servicios prestados mediante el portal de
            Internet (en adelante, el sitio) que se pone a disposición de
            los/las usuarios de Internet. TyC de inscripción: Son los Términos y
            Condiciones de todo el proceso de inscripción y cursado a los
            entrenamientos que Folcademy presta mediante la Escuela de
            entrenamientos.
          </p>
        </div>

        <div class="mt-8">
          <span class="text-sm md:text-xl subtitle1">
            1. Condiciones de uso - Aceptación de términos y condiciones
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              El uso del sitio estará sujeto a los siguientes términos y
              condiciones de uso, en adelante los "Términos y Condiciones", que
              deberán ser cumplidos por los usuarios del mismo, en adelante e
              indistintamente "usted", y/o “cliente” y/o "el usuario". La
              utilización del sitio atribuye al visitante, la condición de
              usuario del sitio e implica su aceptación plena y sin reservas de
              todas y cada una de las disposiciones incluídas en estos “Términos
              y Condiciones Generales”, en la versión publicada por
              <a class="c-pur" href="https://folcademy.com/home"
                >“www.folcademy.com”</a
              >
              en el momento mismo en que el usuario acceda al sitio. En
              consecuencia, el usuario debe leer atentamente los presentes
              “Términos y Condiciones Generales” en cada una de las ocasiones en
              que se proponga utilizar el sitio, ya que esos TyC pueden sufrir
              modificaciones.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            2. Cambios en los términos y condiciones
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Folcademy revisa periódicamente los Términos y Condiciones,
              pudiendo introducir modificaciones, las que producirán efectos
              desde el momento en que son incorporadas al texto. La versión
              modificada de los Términos y Condiciones será publicada en el
              sitio y usted quedará notificado/a en su próximo ingreso en
              carácter de usuario. Si usted no está de acuerdo con los Términos
              y Condiciones modificados, usted deberá dejar de utilizar el
              servicio. El uso del servicio después de que los Términos y
              Condiciones modificados hayan sido publicados, constituirá su
              aceptación de los Términos y Condiciones modificados. Así mismo
              Folcademy se reserva el derecho a suspender temporalmente, sin
              necesidad de previo aviso, el acceso a este sitio para realizar
              operaciones de mantenimiento, actualización, mejora o de
              reparación del sitio. Folcademy tiene la capacidad de emitir
              advertencias, suspensiones temporales y cancelaciones permanentes
              (baja) de los usuarios registrados por infracciones a los
              presentes Términos y Condiciones, así como a cualquier aviso,
              reglamento de uso e instrucción puestos en conocimiento del
              usuario por Folcademy. En ese sentido, Folcademy se reserva el
              criterio con respecto a cuándo y cómo imponer advertencias,
              sanciones y/o acciones disciplinarias. La severidad de un
              incumplimiento y el número de ocurrencias se toman en cuenta en
              las decisiones, pero éstas no son las únicas medidas utilizadas.
              En caso de incumplimiento de los presentes Términos y Condiciones
              Generales, Folcademy tendrá derecho a dar de baja provisionalmente
              al usuario registrado del sitio, previa advertencia de amenaza de
              ello. La advertencia de amenaza de baja resultará innecesaria
              cuando concurran circunstancias especiales que justifiquen la baja
              inmediata del usuario registrado.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            3. Información contenida en el sitio
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Folcademy recaudará información sobre usted o su empresa solamente
              cuando considere que es razonablemente necesario. Nosotros
              recaudamos información privada de las siguientes fuentes:
              Información recibida en solicitudes, encuestas, inscripciones en
              línea, u otras formas, información que recibimos por parte de
              nuestros clientes y otras instituciones, incluyendo información
              relacionada a su historial crediticio. Esta información será
              referida como “información del usuario”. El usuario a través de
              las distintas áreas que forman parte del sitio puede visitar y
              obtener información. Nuestros clientes y usuarios no retienen,
              difunden, almacenan ni utilizan información para ningún propósito
              diferente a los enunciados. La información recibida por Folcademy
              nunca es vendida, cedida o alquilada a otras empresas. Folcademy
              procura que la información contenida en el sitio sea completa,
              exacta y actualizada. Sin embargo, Folcademy, no está en
              condiciones de verificar la exactitud, integridad o vigencia de
              toda la información ofrecida, por lo cual en ningún caso garantiza
              la exactitud, integridad o vigencia de la información contenida en
              el sitio. Tampoco garantiza que los aspectos funcionales del
              sitio, se encuentren libres de error, o que el sitio, o el
              servidor que lo hace disponible al usuario se encuentren libres de
              virus o de cualquier otro componente de carácter dañino, ni
              garantiza que los archivos que se encuentren disponibles para
              descargar se encuentren libres de infección, virus u otras
              manifestaciones de códigos contaminados o propiedades
              destructivas. Folcademy, no es responsable del daño directo,
              indirecto, previsto o imprevisto, o cualquier otro, que provoque
              el uso del sitio, del material o de los servicios ofrecidos en
              este sitio, al sistema de tratamiento de la información del
              usuario, al usuario o a terceros. El usuario reconoce que las
              medidas de seguridad en Internet no son inexpugnables. Procuramos
              que toda la información contenida en el sitio, sea verificada, y
              en caso de que se trate de información directamente suministrada
              por los usuarios, no se garantiza la exactitud, integridad,
              confiabilidad o vigencia del material, conexiones, o del resultado
              que se obtenga del uso del sitio y de sus servicios.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1"> 4. Objeto </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              A través del sitio, Folcademy tiene por objeto promocionar,
              comercializar de manera absolutamente privada y brindar los
              servicios que comprenden la academia de experiencias formativas
              mediante entrenamientos de roles de la industria tech. A tales
              fines, a través del sitio, se buscará poner en conocimiento del
              usuario, los servicios que comprenden su actividad, como así
              también otorgarle publicidad a la marca Folcademy, Folclass y/o
              Folcamp, todo ello a los fines de que el usuario pueda tener
              acceso a dicha información, como así también a las
              “entrenamientos” promocionadas y comercializadas mediante la
              misma, siempre en los términos y condiciones fijados en el
              presente documento. Reconoce el usuario que Folcademy no es una
              entidad educativa reconocida como tal por el Ministerio de
              Educación de la Provincia de San Juan, sino que tal como
              anteriormente se expresa, sus actividades formativas son netamente
              privadas. Esto no obsta a que determinados entrenamientos puedan
              contar con el aval del Ministerio de Educación de la provincia de
              San Juan, lo que se aclarará en el caso de que así suceda.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            5. Condiciones de acceso y utilización del sitio
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              5.1. El acceso al sitio por parte de los usuarios tiene carácter
              gratuito.
            </p>

            <p class="text-sm md:text-xl color-p">5.2. Registro de Usuario</p>

            <p class="text-sm md:text-xl color-p">
              El usuario acepta, por el hecho de registrarse como tal en el
              sitio, el derecho de Folcademy de comunicarse por todas las vías y
              medios posibles, incluídos los electrónicos, hasta tanto soliciten
              la cancelación de su cuenta en el sitio, o hasta donde la
              legislación aplicable sobre la materia lo permita.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            6. Responsabilidades del usuario
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              El usuario se compromete a utilizar el servicio de conformidad con
              la legislación vigente, la moral, las buenas costumbres y el orden
              público, así como a abstenerse de utilizar el servicio con fines o
              efectos ilícitos, prohibidos en los presentes TyC, y/o lesivos de
              los derechos e intereses de terceros, y/o que de cualquier forma
              puedan dañar, inutilizar, sobrecargar o deteriorar los servicios,
              los equipos informáticos de otros usuarios o de otros usuarios de
              Internet (hardware y software), así como los documentos, archivos
              y toda clase de contenidos almacenados en sus equipos informáticos
              (hacking), o impedir la normal utilización o disfrute de dichos
              servicios, equipos informáticos y documentos, archivos y
              contenidos por parte de los demás usuarios y de otros usuarios.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            7. No licencia de uso de propiedad intelectual
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Todas las marcas, nombres comerciales o signos distintivos de
              cualquier clase que aparecen en el sitio son propiedad de Folcode
              S.R.L., o de sus respectivos titulares, sin que pueda entenderse
              que el uso o acceso al sitio y/o a los servicios atribuya al
              usuario derecho alguno sobre las citadas marcas, nombres
              comerciales y/o signos distintivos. En todos los casos, el uso de
              marcas, nombres comerciales y/o signos distintivos de terceros en
              el sitio son únicamente con fines informativos para los usuarios
              del sitio y los consumidores en general. Asímismo, los contenidos,
              imágenes, reseñas, textos y otros derechos de propiedad
              intelectual que aparecen en el sitio son de titularidad de Folcode
              o de sus respectivos titulares y/o autores, sin que puedan
              entenderse cedidos en su derecho de uso, reproducción,
              distribución, comercialización, modificación o explotación
              comercial en cualquier forma, al usuario. En virtud de lo
              establecido en los presentes “Términos y Condiciones Generales”,
              ninguno de los derechos existentes o que puedan existir en el
              futuro sobre dichos contenidos, imágenes, reseñas, textos y otros
              Derechos de Propiedad Intelectual, podrá ser utilizado por el
              usuario más allá de lo estrictamente necesario para el correcto
              uso del sitio y de los servicios ofrecidos, y siempre bajo los
              principios de buena fe y uso honesto de los mismos. El usuario no
              puede descargar, copiar, reproducir, volver a publicar, enviar por
              correo ordinario, transmitir, almacenar, vender o distribuir las
              obras y otros derechos de propiedad intelectual sin el permiso
              escrito previo del sitio o del titular del derecho de propiedad
              intelectual, salvo que se trate de descargar una copia en
              cualquier ordenador únicamente para uso personal, doméstico y no
              comercial, siempre que se mantengan intactas las advertencias
              sobre derechos de propiedad intelectual y cualquier otra
              advertencia de propiedad. Está prohibido modificar el contenido o
              usar parte del contenido o la información proporcionada para otro
              propósito que no sea el establecido en los presentes Términos y
              Condiciones.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            8. Popiedad de los bienes y/o servicios ofertados
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              En relación a lo fijado en el punto 4 del presente, el objeto del
              sitio es otorgar al usuario toda la información relacionada con la
              promoción, comercialización y desarrollo de la academia de
              experiencias formativas y entrenamientos de roles de la industria
              tech.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            9. Exclusión de garantías y de responsabilidad
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              9.1. Exclusión de garantías y de responsabilidad por el
              funcionamiento del sitio. El sitio no garantiza la privacidad y
              seguridad de la utilización del sitio y de sus servicios ofrecidos
              y, en particular, no garantiza que terceros no autorizados no
              puedan tener conocimiento de la clase, condiciones,
              características y circunstancias del uso que los Usuarios hacen
              del sitio y de los servicios allí contenidos.
            </p>

            <p class="text-sm md:text-xl color-p">
              9.2. Exclusión de garantías y de responsabilidad por los
              contenidos
            </p>

            <p class="text-sm md:text-xl color-p">
              9.2.1. Calidad. Folcode no controla, monitorea, ni garantiza la
              ausencia de virus ni de otros elementos maliciosos en los
              contenidos que puedan producir alteraciones en su sistema
              informático (software y hardware) o en los documentos electrónicos
              almacenados en su sistema informático.
            </p>

            <p class="text-sm md:text-xl color-p">
              9.2.2. Licitud, fiabilidad y utilidad. Folcode no garantiza la
              licitud, fiabilidad, utilidad, veracidad, exactitud, legalidad,
              exhaustividad y/o actualidad de los Contenidos propios, o los
              proporcionados por otros Usuarios.
            </p>

            <p class="text-sm md:text-xl color-p">
              9.3. Exclusión de garantías y de responsabilidad por la
              información, contenidos y servicios alojados fuera del sitio. El
              sitio puede poner a disposición de los usuarios dispositivos
              técnicos de enlace (tales como, entre otros, links, banners,
              botones), directorios y herramientas de búsqueda que permiten a
              los usuarios acceder a sitios web pertenecientes y/o gestionados
              por terceros (en adelante, “Sitios Enlazados”). La instalación de
              estos enlaces, directorios y herramientas de búsqueda en el sitio
              tiene por único objeto facilitar a los usuarios la búsqueda y
              acceso a la información, contenidos y servicios disponibles en
              Internet. Los resultados de herramientas de búsqueda son
              proporcionados directamente por terceros y son consecuencia del
              funcionamiento automático de mecanismos técnicos, por lo que
              Folcode no puede controlar y no controla tales resultados y, en
              particular, que entre ellos aparezcan sitios de Internet cuyos
              contenidos puedan resultar ilícitos, contrarios a la moral o a las
              buenas costumbres o considerados inapropiados por otros motivos.
              En el supuesto de que un usuario considere que en alguno de los
              sitios incluídos en los resultados de la búsqueda o enlaces de
              terceros se presenta alguna actividad o información ilícita y esté
              interesado en solicitar la supresión del enlace, debe poner en
              conocimiento a Folcode de tal situación mediante las vías de
              contacto existentes el sitio. Folcode no ofrece ni comercializa
              por sí ni por medio de terceros la información, contenidos y
              servicios disponibles en los sitios enlazados, ni los controla
              previamente, aprueba, recomienda, vigila ni hace propios. El
              usuario, por tanto, debe extremar la prudencia en la valoración y
              utilización de la información, contenidos y servicios existentes
              en los sitios enlazados. El Usuario reconoce y acepta que Folcode
              no es parte en ningún contacto, comunicación, intercambio de
              información, promesas, negociaciones, ofertas y/u operaciones que
              pueda tener con otros usuarios y terceros, incluídos anunciantes
              del sitio. Folcode no puede verificar la identidad o datos
              personales ingresados por los usuarios.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            10. Protección de datos personales
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Para utilizar los servicios ofrecidos por Folcode, en algunos
              casos los usuarios deberán facilitar determinados datos de
              carácter personal. Desde el momento de su ingreso y/o utilización
              al sitio el usuario consiente expresamente a Folcode para el
              tratamiento de los datos personales que por él sean facilitados o
              que se faciliten a través de su ingreso al sitio o por cualquier
              medio. Asímismo, el usuario consiente expresamente que Folcode
              pueda ceder dichos datos personales a terceros. Los datos
              personales serán incorporados a la base de datos de clientes de
              titularidad de Folcode. Sus datos serán utilizados en la gestión
              administrativa y comercial de la petición del usuario y de una
              eventual relación contractual con Folcode. Su información personal
              se procesa y almacena en servidores o medios magnéticos que
              mantienen altos estándares de seguridad y protección tanto física
              como tecnológica. Una vez registrados los datos del usuario,
              Folcode no venderá, alquilará o compartirá la información
              personal, excepto en las formas establecidas en sus políticas, y
              en los límites permitidos por la normativa vigente. Puede suceder
              que en virtud de órdenes judiciales, o de regulaciones legales,
              Folcode se vea obligado a revelar información a las autoridades o
              terceras partes bajo ciertas circunstancias, o bien en casos que
              terceras partes puedan interceptar o acceder a cierta información
              o transmisiones de datos, en cuyo caso Folcode no responderá por
              la información que sea revelada. El Usuario presta su
              consentimiento para que se puedan transferir sus datos en los
              términos y condiciones anteriormente indicados, a otras entidades,
              incluso con destino a un país extranjero. Los datos se mantendrán
              en la base de datos de Folcode mientras se consideren útiles para
              enviarle al usuario por cualquier medio o soporte información de
              Folcode y de terceros que considere pertinente, así como para
              realizar encuestas, estadísticas, etc. El Usuario autoriza a
              Folcode a conservar sus datos para que esta información forme
              parte de la Big Data de la empresa, y para que el usuario pueda
              recibir información publicitaria y ofertas comerciales. El Usuario
              puede ejercer los derechos de acceso, rectificación, oposición y
              cancelación de los datos personales, los que se podrá ejercer
              mediante la opción de contacto que ponemos a su disposición. El
              usuario responde de la veracidad de los datos facilitados por él,
              reservándose Folcode el derecho a excluirlo, imponer las sanciones
              o realizar las denuncias respectivas, en caso de constatar la
              falsedad o inexactitud de los mismos. El usuario, por el simple
              hecho de registrarse y/o utilizar el sitio, acepta que éste le
              envíe toda clase de anuncios y publicidad (incluído, pero no
              limitado a correos electrónicos comerciales, folletos, cartas,
              etc.) a su dirección física y/o virtual declarada, con las
              novedades, ofertas y promociones de los servicios que brinda. En
              cualquier caso, si el usuario no desea seguir recibiendo tales
              comunicaciones, podrá revocar la autorización de recepción
              otorgada mediante la presente, para lo cuál deberá comunicarse con
              Folcode por medio del sitio, a fin de ser eliminado de su base de
              datos.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            11. Requerimientos del sistema
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Al utilizar el sitio, el usuario acepta que el hardware, software
              y acceso a Internet juega un papel crucial en su experiencia de
              navegación. En ese sentido, el Usuario acepta que Folcode no es
              responsable por ningún inconveniente relacionado al hardware,
              software o acceso a Internet del propio usuario, y por tanto exime
              de responsabilidad a Folcode por dichos problemas. El usuario es
              el único y completo responsable por los gastos de conexión a
              Internet, así como por todo el equipo necesario para ello, su
              revisión, reparación o corrección y cualquier otro costo o gastos
              por el mantenimiento de los mismos.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            12. Corte del servidor
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Existirán períodos regulares de mantenimiento del servidor así
              como de mantenimiento del sitio. Folcode hará su mejor esfuerzo
              para reducir en la mayor medida de lo posible el tiempo de
              inactividad del servidor. Sin embargo, Folcode no es responsable
              por ningún daño sufrido por el usuario como resultado del
              mantenimiento u otros tiempos de inactividad del servidor o el
              sitio. El usuario acuerda eximir de responsabilidad a Folcode por
              cualquier daño sufrido, incluído el lucro cesante y daño emergente
              que pueda ocasionarse por tales hechos. Folcode no será
              responsable por ningún daño o perjuicio sufrido por el usuario
              debido a causas imprevistas o causas fuera del control de Folcode,
              tales como actos de naturaleza, guerra, terrorismo, motines,
              embargos, actos de autoridades civiles o militares, incendio,
              inundaciones, accidentes, huelgas o escasez de facilidades de
              transporte, combustible, energía, trabajo y materiales.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            13. Modificación del sitio web
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Folcode se reserva el derecho a eliminar o modificar cualquier
              parte del sitio, en cualquier momento y sin previo aviso.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            14. Legislación y juridicción aplicable
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Los presentes “Términos y Condiciones” se rigen en todos y cada
              uno de sus extremos por las leyes de la República Argentina.
              Folcode y el usuario, con renuncia expresa a cualquier otro fuero,
              se someten al de los Juzgados y Tribunales de San Juan, Argentina.
            </p>
          </div>
        </div>

        <div class="mt-5">
          <span class="text-sm md:text-xl subtitle1">
            14. Legislación y juridicción aplicable
          </span>

          <div class="mt-5">
            <p class="text-sm md:text-xl color-p">
              Los presentes “Términos y Condiciones” se rigen en todos y cada
              uno de sus extremos por las leyes de la República Argentina.
              Folcode y el usuario, con renuncia expresa a cualquier otro fuero,
              se someten al de los Juzgados y Tribunales de San Juan, Argentina.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-3 lg:mb-6 text-center mt-20">
        <span
          class="title-section mb-2 md:mb-4 text-2xl font-semibold md:text-5xl text-center mt-16"
        >
          Términos y condiciones de inscripción
        </span>
      </div>

      <div class="mt-20">
        <span class="text-sm md:text-xl subtitle1">
          *INSCRIPCIONES: Proceso de inscripción
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            La inscripción a los entrenamientos deberá efectuarse vía web en
            <a
              class="c-pur"
              href="https://folcademy.folclass.com/auth/login/platform"
              >https://folcademy.folclass.com/platform.</a
            >
            El usuario deberá, crear un usuario, completar el registro
            correspondiente y seleccionar el/los entrenamientos a desarrollar
            teniendo en cuenta las fechas y horarios que se ajusten a su
            responsabilidad en tanto haya vacantes, efectuar el pago de acuerdo
            a las instrucciones que surjan de la plataforma.
          </p>
          <p class="text-sm md:text-xl color-p">
            Una vez completada la inscripción, recibirá un mail de bienvenida
            con el detalle de la inscripción e instrucciones necesarias para
            continuar.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1"> *Cupos limitados </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            Los cupos a los entrenamientos son limitados. El cupo, se confirma y
            se reserva únicamente habiendo realizado el pago del mismo por
            cualquiera de los medios habilitados a tal fin. El cupo del curso se
            cerrará cuando se completen las vacantes del mismo.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1">
          Reprogramación y/o Cancelación de entrenamientos
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            Folcademy se reserva el derecho de reprogramar y/o cancelar los
            entrenamientos según lo disponga. En el caso de cancelaciones o
            reprogramaciones, los usuarios que ya hayan abonado el
            entrenamiento, podrán optar por utilizar el saldo en otro curso de
            su interés, transferirlo a otra persona u optar por el reembolso.
            <br />
            Asímismo, Folcademy se reserva el derecho a modificar la fecha de
            inicio del curso con previo aviso a los estudiantes por falta de
            asignación profesor o modificaciones en el contenido. <br />
            Este tipo de cambios se avisarán por cualquiera de las vías de
            comunicación que tiene Folcademy con sus usuarios.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1">
          Cambios de entrenamientos. Opciones en relación a los pagos
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            El usuario, podrá solicitar el cambio del entrenamiento en el que se
            inscriba, según las siguientes condiciones:
          </p>
          <p class="text-sm md:text-xl color-p">
            A. Si la solicitud la realiza antes del inicio del entrenamiento
            originario: Hasta tres (3) días hábiles antes de la fecha de inicio,
            se puede solicitar el cambio, siempre y cuando aún haya
            disponibilidad en el nuevo entrenamiento solicitado.
          </p>
          <p class="text-sm md:text-xl color-p">
            B. Si la solicitud de cambio se realiza una vez iniciado el
            entrenamiento originario: Sólo en caso de que al momento de realizar
            la solicitud se cumplan las siguientes condiciones:
          </p>
          <p class="text-sm md:text-xl color-p">
            1. La solicitud de cambio se realice dentro de los primeros cinco
            (5) días hábiles de iniciado el entrenamiento;
          </p>

          <p class="text-sm md:text-xl color-p">
            2. El entrenamiento al que desee cambiarse tenga vacantes de cupos y
            mentores suficientes.
          </p>

          <p class="text-sm md:text-xl color-p">
            Cumplidas estas dos condiciones, el cambio se hará efectivo dentro
            de los tres (3) días hábiles posteriores a que Folcademy reciba la
            solicitud y verifique la disponibilidad de cursos.
          </p>
          <p class="text-sm md:text-xl color-p">
            En ambos casos (A y B), si el entrenamiento por el que el usuario
            opta cambiar tiene un valor mayor al del entrenamiento originalmente
            contratado, el usuario deberá abonar la diferencia.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1">
          Desistimiento del Usuario
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            El usuario que decida abandonar el entrenamiento y no opte por la
            opción de “cambio de entrenamientos”, sólo podrá solicitar la
            devolución del dinero si desiste del entrenamiento dentro de la
            primera semana de iniciado el cursado de la misma. Vencido dicho
            plazo, el dinero no es reembolsable.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1"> Condiciones de pago </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            El usuario que decida abandonar el entrenamiento y no opte por la
            opción de “cambio de entrenamientos”, sólo podrá solicitar la
            devolución del dinero si desiste del entrenamiento dentro de la
            primera semana de iniciado el cursado de la misma. Vencido dicho
            plazo, el dinero no es reembolsable.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1">
          Entrenamientos: formas y modalidades
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            La duración y modalidad del cursado de los entrenamientos, como así
            también el cronograma, tareas y/o actividades a desarrollar cada
            semana, entregas, trabajos, evaluaciones y feedback de los
            mentores/profesores y/o cualquier otro aspecto en relación a las
            formas del cursado de los entrenamientos se especificarán por
            Folcademy en su
            <a class="c-pur" href="https://folcademy.folclass.com/"
              >sitio web</a
            >
            en los apartados “Acerca del entrenamiento” y “Cronograma” que se
            encuentran dentro de cada entrenamiento.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1">
          Pagos de cada entrenamiento
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            Para cada caso concreto de cada entrenamiento puntual, es que en el
            <a class="c-pur" href="https://folcademy.folclass.com/"
              >sitio web</a
            >
            se publicará todo lo referido a aspectos que, a modo enunciativo
            pero no limitativo, se refiera a: modalidad de pagos, facturaciones,
            entidades bancarias admitidas, pago de comisiones bancarias,
            impuestos por tales operaciones, descuentos, promociones, plazos
            para efectuar los pagos, intereses, modo de reclamo de esos montos,
            devolución, etc.
          </p>
          <p class="text-sm md:text-xl color-p">
            Todo esto, siempre que no se encuentren tales aspectos ya
            desarrollados en el presente TyC.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1"> Material de estudio </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            El material de estudio que recibe el usuario de acuerdo al
            entrenamiento contratado, estará disponible en la plataforma de
            folclass y quedará a disposición del usuario por un año contados a
            partir de la finalización del entrenamiento.
          </p>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-sm md:text-xl subtitle1">
          Certificados de finalización:
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            Todos los participantes de los entrenamientos, al finalizar el mismo
            podrán acceder a un “Certificado de finalización de entrenamiento”,
            el cual quedará a disposición de cada participante en
            <a class="c-pur" href="https://folcademy.folclass.com/"
              >“el sitio”</a
            >, para que lo descarguen. Asímismo, estos podrán solicitarlo a
            Folcademy hasta un año posterior a la finalización del curso.
            Cumplido este plazo, el participante nada podrá reclamar a Folcademy
          </p>
        </div>
      </div>

      <div class="mt-5 sep2 bg-neutro">
        <span class="text-sm md:text-xl subtitle1">
          Legislación y Jurisdicción aplicable
        </span>

        <div class="mt-5">
          <p class="text-sm md:text-xl color-p">
            Los presentes “Términos y Condiciones de Inscripción” se rigen en
            todos y cada uno de sus extremos por las leyes de la República
            Argentina. Folcode y el usuario, con renuncia expresa a cualquier
            otro fuero, se someten al de los Juzgados y Tribunales de San Juan,
            Argentina.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
