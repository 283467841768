<main class="main">
  <!-- Primera sección HERO -->

  <section class="section-1">
    <div class="h-full w-full flex items-center justify-center">
      <div class="flex-col w-4/5 ct py-10 px-5 flex items-center justify-center bg-hero section-10">
        <!-- <h4 class="title">¿Sabías que la industria IT te espera? </h4> -->
        <p class="subtitle">
          CAPACITÁ A TU EQUIPO <br />
          EN TECNOLOGÍA
        </p>
        <!-- <button mat-flat-button color="accent" class="btn" (click)="scrollTo('trainings')">VER
                    ENTRENAMIENTOS</button> -->
        <a mat-flat-button href="https://7yuum61pe2g.typeform.com/to/UOHDbiNY" target="_blank" mat-flat-button
          class="btn">CONTÁCTANOS</a>
      </div>
    </div>
  </section>

  <section class="flex justify-center section-4 pt-10 md:py-16">
    <div class="lg:w-4/5 flex flex-col justify-around h-full">
      <div
        class="w-10/12 lg:w-full mx-auto lg:mx-0 text-xs md:text-base break-words h-2/6 md:h-2/6 flex flex-col items-center justify-center">
        <!-- ENTRENAMIENTO POR ROL -->
        <span
          class="w-full title-section mb-8 text-2xl font-semibold md:text-5xl text-center"
          >Nuestra propuesta
        </span>
        <!-- <span class="lg:w-7/12">
                    <p class="text-sm md:text-xl text-center color-primary-500">Entrenamientos 100% prácticos, online y en vivo </p>
                </span> -->
      </div>
    </div>
  </section>

  <section class="flex justify-center items-center">

    <div class="grid flex justify-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div class="grid">
        <article class="article1">
          <img src="/assets/company.png" class="mga" />

          <div class="py-8 w-4/5 mx-auto">
            <a><span class="tag2">Nuestra propuesta</span></a>
            <div class="esp2"></div>
            <div class="w-full">
              <div class="mb-3 lg:mb-6">
                <span class="font-bold text-2xl lg:text-5xl leading-4 title-section2">Programas in company
                </span>
              </div>
              <p class="text-xs lg:text-2xl leading-4 lg:leading-10 p-sty1">
                Destinados a grandes poblaciones de equipos de trabajo interno.
                Entrenamientos 100% prácticos, online y en vivo para formar a tus
                colaboradores.
              </p>
            </div>
          </div>
        </article>

        <article class="article2">
          <img src="/assets/alianza.png" class="mga" />
          <div class="py-8 w-4/5 mx-auto">
            <a><span class="tag2">Nuestra propuesta</span></a>
            <div class="esp2"></div>
            <div class="w-full">
              <div class="mb-3 lg:mb-6">
                <span class="font-bold text-2xl lg:text-5xl leading-4 title-section3">Alianzas</span>
              </div>
              <p class="text-xs lg:text-2xl leading-4 lg:leading-10 p-sty2">
                Ofrecé descuentos y beneficios a tus colaboradores y clientes para
                formarse en roles de tecnología.
              </p>
            </div>
          </div>
        </article>

      </div>



      <article class="article3">
        <img src="/assets/becas.png" class="mga" />
        <div class="py-8 w-4/5 mx-auto">
          <a><span class="tag2">Nuestra propuesta</span></a>
          <div class="esp2"></div>
          <div class="w-full">
            <div class="mb-3 lg:mb-6">
              <span class="font-bold text-2xl lg:text-5xl leading-4 title-section2">Programas de becas para tu
                comunidad</span>
            </div>
            <p class="text-xs lg:text-2xl leading-4 lg:leading-10 p-sty1">
              Creá impacto social en tu comunidad a través de nuestros
              entrenamientos. Incluye selección, evaluación y seguimiento de los
              beneficiarios con el objetivo de generar inclusión.
            </p>
          </div>
        </div>
      </article>

    </div>



  </section>

  <section class="section-2">
    <div class="h-full w-full flex items-center justify-center"></div>
  </section>

  <!-- <section
    class="w-full pt-7 md:py-10 flex flex-col justify-center items-center section-9"
  >
    <span
      class="rounded-full bg-neutro color-primary-800 tam1 md:text-base lg:text-lg font-bold px-2 md:px-4 py-1"
      >ESTADO DE LA CONVOCATORIA</span
    >
  </section> -->

  <section class="py-8 w-4/5 mx-auto bg-neutro">
    <a><span class="tag1">Nuestra propuesta</span></a>
    <div class="esp2"></div>
    <div class="w-full">
      <div class="mb-3 lg:mb-6">
        <span class="font-bold text-2xl lg:text-5xl leading-4 title-section1">Folcamp</span>
      </div>
      <p class="text-xs lg:text-2xl leading-4 lg:leading-10 p-sty">
        Campamento de tecnología intensivo de 5 meses de duración en el que a
        través de la formación de equipos interdisciplinarios, las personas
        trabajan en el desarrollo de un producto de tecnología real para una ONG
        de alto impacto.
      </p>
    </div>
  </section>
</main>