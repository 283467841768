import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrequentQuestionsComponent } from './frequent-questions/frequent-questions.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FrequentQuestionsOngComponent } from './frequent-questions-ong/frequent-questions-ong.component';

@NgModule({
  declarations: [
    FrequentQuestionsComponent,
    FrequentQuestionsOngComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    IvyCarouselModule
  ],
  exports: [
    FrequentQuestionsComponent,
    FrequentQuestionsOngComponent,
  ],

})
export class SharedModule { }
