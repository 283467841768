import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tyc',
  templateUrl: './tyc.component.html',
  styleUrls: ['./tyc.component.scss']
})
export class TycComponent implements OnInit {

  constructor(
    private router: Router
) { }

  ngOnInit(): void {
    this.detectedScreen();
  }

  sendId(id: string) {
    // // this._layoutService.sectionId = id;
    this.router.navigate(["/tyc", id])
    // console.log("se mandó", id)
    // this.homeComponent.scrollTo(id)
}

isMobile: boolean = false;

detectedScreen() {
  if (screen.width <= 769) return this.isMobile = true;
  if (screen.width > 770) return this.isMobile = false;
  else return this.isMobile = false;
}


}
