import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { TenantService } from "src/app/core/services/tenant.service";

@Injectable()
export class TenantInterceptor implements HttpInterceptor {

  constructor(private _tenantServices: TenantService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._tenantServices.getTenant()) {
      request = this.addTenant(request, this._tenantServices.getTenant());
    }
    return next.handle(request);
  }

  private addTenant(request: HttpRequest<any>, tenant: string) {
    if(request.headers.get('skip-tenant')){
      return request.clone({
        headers: request.headers.delete('skip-tenant')
      });
    }
    return request.clone({
      setHeaders: {
        'X-Tenant-ID': tenant
      }
    });
  }
}