<main>
  <section class="section-1">
    <div class="h-full w-full flex items-center justify-center">
      <div
        class="flex-col w-4/5 py-10 px-5 flex items-center justify-center bg-hero ct section-10"
      >
        <h4 class="title">Transformá digitalmente tu ONG</h4>
        <p class="subtitle">
          ACCEDÉ A UN PRODUCTO <br />
          DIGITAL ESCALABLE Y GRATUITO
        </p>
        <a
          mat-flat-button
          href="https://7yuum61pe2g.typeform.com/to/UOHDbiNY"
          target="_blank"
          mat-flat-button
          class="btn"
          >QUIERO SABER MÁS</a
        >
        <!-- <button mat-flat-button class="btn" (click)="scrollTo('ONG')">POSTULAR MI ONG</button> -->
      </div>
    </div>
  </section>

  <section
    class="w-full pt-7 md:py-10 flex flex-col justify-center items-center section-9"
  >
    <span
      class="rounded-full bg-neutro color-primary-800 tam1 md:text-base lg:text-lg font-bold px-2 md:px-4 py-1"
      >ESTADO DE LA CONVOCATORIA</span
    >
    <div
      class="w-10/12 md:w-full mx-auto md:mx-0 md:mb-0 h-1/6 md:h-1/6 flex flex-col justify-center items-center"
    >
      <span
        class="title-section1 mb-2 md:mb-4 font-semibold text-2xl md:text-5xl text-center"
        >Convocatoria cerrada
      </span>
      <div class="md:w-9/12">
        <p class="tam md:text-xl color-neutro text-center">
          Envíanos un mail a hi@folcademy.com y te avisaremos cuando abramos la
          próxima.
        </p>
      </div>
    </div>

    <!-- <img class="h-max md:h-32 lg:h-44 w-11/12 md:w-full pt-5 pb-7 md:py-0 md:mb-5"
            src="https://storefolclass.s3.amazonaws.com/public/landing/640c9bb4-85f1-43b0-90cc-39eca41a8a02.svg"
            alt="imagen roadmap folcamp"> -->
  </section>

  <section class="flex justify-center section-2 h-full w-full pt-10 md:py-16">
    <div class="lg:w-4/5 flex flex-col justify-around h-full">
      <!-- ENTRENAMIENTO POR EQUIPOS -->
      <div
        class="w-10/12 md:w-full flex flex-col items-center justify-center mx-auto md:mx-0 mb-5 md:mb-0 text-xs md:text-base break-words h-1/6 md:h-1/6"
      >
        <span
          class="title-section mb-2 md:mb-4 text-2xl font-semibold md:text-5xl text-center"
        >
          Folcamp
        </span>
        <div class="md:w-9/12">
          <p class="text-sm md:text-xl text-center color-primary-500">
            Campamento de tecnología, en el que alumnxs que se formaron
            previamente en los entrenamientos por rol, son parte de una
            experiencia práctica e intensiva de 5 meses, participando en
            proyectos reales de alto impacto. Es acá donde tu ONG se vuelve
            protagonista.
          </p>
        </div>
      </div>
      <ng-container>
        <div
          *ngIf="isMobile; else notCarousel"
          class="flex w-screen justify-center mx-0"
        >
          <carousel
            [cellWidth]="'100%'"
            [arrows]="true"
            [height]="330"
            [margin]="100"
            [cellsToShow]="1"
            [dots]="false"
            style="width: 100% !important"
          >
            <div
              class="carousel-cell w-full flex flex-col items-center justify-center"
            >
              <div class="w-full flex items-center justify-center">
                <div class="w-2/4 flex flex-row items-center justify-center">
                  <span class="font-bold color-primary-500 text-8xl mr-1"
                    >1</span
                  >
                  <span class="text-light color-primary-500"
                    >FORMAMOS UN <br />
                    EQUIPO DE <br />
                    TRABAJO ÁGIL</span
                  >
                </div>
              </div>
              <div class="w-9/12 h-32">
                <img
                  class=""
                  src="https://storefolclass.s3.amazonaws.com/public/landing/277e97e5-b74a-47ea-a030-14bdc4545613.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div class="flex flex-col items-center justify-center my-2">
                <div class="w-full flex items-center justify-center">
                  <div
                    class="w-2/4 flex flex-row items-center justify-center mb-5"
                  >
                    <span class="font-bold color-primary-500 text-8xl mr-1"
                      >2</span
                    >
                    <span class="text-light color-primary-500"
                      >ACTIVAMOS <br />
                      NUESTRO <br />
                      PROCESO</span
                    >
                  </div>
                </div>
                <div class="w-9/12 h-44">
                  <img
                    class=""
                    src="https://storefolclass.s3.amazonaws.com/public/landing/fbdc6530-6ffc-4949-8d8a-fc83ae766fe5.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="flex flex-col items-center justify-center my-2">
                <div class="w-full flex items-center justify-center">
                  <div
                    class="w-2/4 flex flex-row items-center justify-center mb-5"
                  >
                    <span class="font-bold color-primary-500 text-8xl mr-1"
                      >3</span
                    >
                    <span class="text-light color-primary-500"
                      >GENERAMOS <br />
                      IMPACTO</span
                    >
                  </div>
                </div>
                <div class="w-9/12 h-44">
                  <img class="" src="/assets/imagenFolcademy.svg" alt="" />
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <ng-template #notCarousel>
          <div class="flex flex-col items-center mt-16">
            <div class="w-10/12 flex flex-col justify-center">
              <div class="flex items-center justify-center my-4">
                <div class="h-full w-1/4 flex items-center">
                  <div
                    class="w-full flex flex-row items-center justify-center mb-8"
                  >
                    <span class="font-bold color-primary-500 text-8xl mr-2"
                      >1</span
                    >
                    <span class="text-light color-primary-500"
                      >FORMAMOS UN <br />
                      EQUIPO DE <br />
                      TRABAJO ÁGIL</span
                    >
                  </div>
                </div>
                <div class="w-3/4">
                  <img
                    class="w-full h-48"
                    src="https://storefolclass.s3.amazonaws.com/public/landing/277e97e5-b74a-47ea-a030-14bdc4545613.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="flex items-center justify-center my-2">
                <div class="h-full w-1/4 flex items-center">
                  <div class="w-full flex flex-row items-center justify-center">
                    <span class="font-bold color-primary-500 text-8xl mr-2"
                      >2</span
                    >
                    <span class="text-light color-primary-500"
                      >ACTIVAMOS <br />
                      NUESTRO <br />
                      PROCESO</span
                    >
                  </div>
                </div>
                <div class="w-3/4">
                  <img
                    class="w-full h-80"
                    src="https://storefolclass.s3.amazonaws.com/public/landing/fbdc6530-6ffc-4949-8d8a-fc83ae766fe5.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="flex items-center justify-center my-2">
                <div class="h-full w-1/4 flex items-center">
                  <div class="w-full flex flex-row items-center justify-center">
                    <span class="font-bold color-primary-500 text-8xl mr-2"
                      >3</span
                    >
                    <span class="text-light color-primary-500"
                      >GENERAMOS <br />
                      IMPACTO</span
                    >
                  </div>
                </div>
                <div class="w-3/4">
                  <img
                    class="w-full h-80"
                    src="/assets/imagenFolcademy.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </section>

  <!-- NUESTRA EXPERIENCIA -->
  <section
    class="flex justify-center section-3 h-full w-full md:mb-0 py-10 md:py-16"
  >
    <div class="md:w-4/5 flex flex-col justify-around md:m-0 h-full">
      <div class="flex flex-col justify-between">
        <div
          class="w-10/12 md:w-full mx-auto md:mx-0 md:mb-0 h-1/6 md:h-1/6 flex flex-col justify-center items-center"
        >
          <span
            class="title-section mb-2 md:mb-4 font-semibold text-2xl md:text-5xl text-center"
            >Nuestra experiencia
          </span>
          <div class="md:w-9/12">
            <p class="text-sm md:text-xl color-primary-500 text-center">
              Queremos transformar el mundo a través de la tecnología y creemos
              que la educación es el camino para hacerlo.
            </p>
          </div>
        </div>
        <ul class="w-10/12 mx-auto lg:mx-0 lg:w-full color-items md:mt-4">
          <li
            *ngFor="let info of dataExperience"
            class="flex items-center my-8"
          >
            <img
              style="height: 3rem; width: 3rem"
              src="../../../../../assets/estrella.svg"
              alt="icono de estrella"
            />
            <div class="flex items-center mx-3 break-words">
              <span class="text-xl md:text-5xl mr-5 font-bold">{{
                info.number
              }}</span>
              <span class="text-xs md:text-2xl">{{ info.text }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="flex justify-center section-4 py-10 md:py-16">
    <div class="md:w-4/5 flex flex-col justify-around">
      <!-- PROYECTOS FOLCAMP -->
      <div class="flex flex-col w-full justify-between items-center">
        <div
          class="w-10/12 mx-auto md:mx-0 text-xs md:text-base break-words flex justify-center"
        >
          <span class="color-neutro font-bold text-2xl md:text-5xl text-center"
            >Proyectos reales de Folcamp</span
          >
        </div>
        <div
          *ngIf="isMobile; else twoCarousel"
          class="h-max w-screen mx-0 flex justify-center pb-8"
        >
          <carousel
            [cellWidth]="'100%'"
            [cellsToShow]="1"
            [margin]="100"
            [dots]="true"
            [arrows]="false"
            style="min-height: 550px"
          >
            <div
              *ngFor="let data of proyectsONG"
              class="carousel-cell h-max w-max flex items-center justify-center"
            >
              <div
                class="w-80 h-5/6 mb-5 border-4 drop-shadow-md border-white flex flex-col justify-center items-center"
              >
                <img
                  style="height: 100px; max-width: 200px"
                  [src]="data.img"
                  alt="proyecto folcamp"
                />
                <p
                  class="w-5/6 mx-auto text-xs py-3 h-max color-neutro break-words"
                >
                  {{ data.text }}
                </p>
                <a
                  ><span class="tag1">{{ data.tag }}</span></a
                >
              </div>
            </div>
          </carousel>
        </div>
        <ng-template #twoCarousel>
          <div class="flex justify-center h-max pb-16 pt-8">
            <carousel
              [height]="700"
              [width]="carouselWidth"
              [cellsToShow]="cellsToShow"
              [cellWidth]="innerWidth"
              [arrowsOutside]="true"
              [dots]="true"
              [arrows]="true"
            >
              <div
                *ngFor="let data of proyectsONG"
                class="carousel-cell w-max h-full flex justify-center"
                style="height: 640px !important; max-width: 992px !important"
              >
                <div
                  style="
                    height: 640px !important;
                    max-width: 992px !important;
                    padding: 56px 32px;
                    gap: 24px;
                  "
                  class="border-4 drop-shadow-md py-3 border-white flex flex-col justify-center items-center"
                >
                  <img
                    style="
                      height: 160px;
                      width: 332px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      padding: 10px;
                      gap: 10px;
                    "
                    [src]="data.img"
                    alt="proyecto folcamp"
                  />
                  <p
                    id="ONG"
                    class="parrafo mx-auto py-3 h-max color-neutro break-words"
                  >
                    {{ data.text }}
                  </p>
                  <a
                    ><span class="tag">{{ data.tag }}</span></a
                  >
                </div>
              </div>
            </carousel>
          </div>
        </ng-template>
      </div>

      <!-- Form ONG -->
      <div class="flex flex-col justify-between items-center mx-5 md:mx-0">
        <span
          class="w-full color-neutro font-light text-lg md:text-2xl text-center mb-8"
          >Si querés conocer más detalles para que tu ONG sea parte <br />
          del Folcamp, hacé click y contáctanos
        </span>
        <div class="w-full flex items-center justify-center">
          <a
            href="https://7yuum61pe2g.typeform.com/to/UOHDbiNY"
            target="_blank"
            mat-flat-button
            color="accent"
            class="btn"
            >QUIERO SABER MÁS</a
          >
        </div>
      </div>

      <!-- TESTIMONIO FOLCAMPERS -->
      <div class="flex flex-col justify-between items-center pt-4 md:pt-8">
        <div
          class="py-8 md:py-16 w-10/12 mx-auto md:mx-0 text-xs md:text-base break-words flex justify-center"
        >
          <span class="color-neutro font-bold text-2xl md:text-5xl text-center"
            >Testimonios de Folcampers</span
          >
        </div>
        <div
          *ngIf="isMobile; else galeryTestimony"
          class="flex w-screen h-max mx-0 justify-center"
        >
          <carousel
            [cellWidth]="'100%'"
            [cellsToShow]="1"
            [margin]="100"
            [arrows]="true"
            style="height: 500px"
          >
            <div class="carousel-cell flex justify-center h-max w-3/5">
              <div class="drop-shadow-md mb-10">
                <iframe
                  width="250px"
                  height="450px"
                  src="https://www.youtube.com/embed/9wakp4HVR4c"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="carousel-cell flex justify-center h-max w-3/5">
              <div class="drop-shadow-md mb-10">
                <iframe
                  width="250px"
                  height="450px"
                  src="https://www.youtube.com/embed/Kat-xRJo3ow"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </carousel>
        </div>
        <ng-template #galeryTestimony>
          <div class="flex items-center justify-center h-5/6 w-full">
            <div class="mx-5 drop-shadow-md">
              <iframe
                width="250px"
                height="450px"
                src="https://www.youtube.com/embed/9wakp4HVR4c"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="mx-5 drop-shadow-md">
              <iframe
                width="250px"
                height="450px"
                src="https://www.youtube.com/embed/Kat-xRJo3ow"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- <div class="pt-10 w-full h-full md:h-4/6">
                <div class="mb-5 w-4/5 mx-auto md:mx-0">
                    <h2 class="mb-0 text-xs md:text-base break-words title-section">Testimonios de alumnxs
                    </h2>
                </div>
                <div *ngIf="isMobile; else galeryTestimony" class="md:h-max h-5/6 flex w-screen mx-0 justify-center">
                    <carousel [cellWidth]="'100%'" [cellsToShow]="1" [margin]="100" [dots]="true"
                        style=" width: 100%; height: 100%;">
                        <div class="carousel-cell flex items-center justify-center flex-col h-full w-3/5"
                            style="max-height: 600px;">
                            <div class="border-4 drop-shadow-md border-white">
                                <iframe width="250px" height="450px" src="https://www.youtube.com/embed/9wakp4HVR4c"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="carousel-cell flex items-center justify-center flex-col h-full w-3/5"
                            style="max-height: 600px;">
                            <div class="border-4 drop-shadow-md border-white">
                                <iframe width="250px" height="450px" src="https://www.youtube.com/embed/Kat-xRJo3ow"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </carousel>
                </div>
                <ng-template #galeryTestimony>
                    <div class="flex items-center justify-center h-5/6 w-full">
                        <div class="mx-5 border-4 drop-shadow-md border-white">
                            <iframe width="250px" height="450px" src="https://www.youtube.com/embed/9wakp4HVR4c"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                        <div class="mx-5 border-4 drop-shadow-md border-white">
                            <iframe width="250px" height="450px" src="https://www.youtube.com/embed/Kat-xRJo3ow"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </ng-template>
            </div> -->
    </div>
  </section>

  <!-- Preguntas frecuentes -->
  <section class="flex justify-center py-10 h-max section-5">
    <div class="md:w-4/5 w-4/5 h-full">
      <div class="mx-auto">
        <h2 class="font-bold color-neutro">Preguntas frecuentes</h2>
      </div>
      <div class="my-3">
        <app-frequent-questions-ong></app-frequent-questions-ong>
      </div>
    </div>
  </section>
</main>
