<div>
    <div>
        <app-header-general (sidenavToggle)="sidenav.toggle()"></app-header-general>
        <mat-sidenav-container>
            <mat-sidenav #sidenav position="end" role="navigation">
                <app-sidenav (sidenavClose)="sidenav.close()"></app-sidenav>
            </mat-sidenav>
            <mat-sidenav-content>
                <router-outlet (activate)="onActivate($event)"></router-outlet>
                <app-footer-general></app-footer-general>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>