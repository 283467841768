import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoutesRoutingModule } from './routes-routing.module';
import { HomeComponent } from './components/home/home.component';
import { MatButtonModule } from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { SharedModule } from '../shared/shared.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { OngComponent } from './components/ong/ong.component';
import { UsComponent } from './components/us/us.component';
import { TycComponent } from './components/tyc/tyc.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    HomeComponent,
    OngComponent,
    UsComponent,
    TycComponent,
    EmpresaComponent
  ],
  imports: [
    CommonModule,
    RoutesRoutingModule,
    SharedModule,
    MatButtonModule,
    MatGridListModule,
    IvyCarouselModule,
    HttpClientModule,
    MatCardModule,
    MatProgressSpinnerModule,
    LottieModule.forRoot({ player: playerFactory })
  ]
})
export class RoutesModule { }
