import { ChangeDetectionStrategy, Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HomeComponent } from 'src/app/core/routes/components/home/home.component';
import { LayoutsService } from '../../services';

@Component({
    selector: 'app-header-general',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './header-general.component.html',
    styleUrls: ['header-general.component.scss'],
})
export class HeaderGeneralComponent implements OnInit {
    @Output() public sidenavToggle = new EventEmitter();
    homeComponent!: HomeComponent;
    tenantName: string = '';
    platformRedirect: string = '';

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        if (localStorage.getItem('tenant')) {
            this.tenantName = JSON.parse(localStorage.getItem('tenant')!).tenantName;
            if (this.tenantName === 'devfolcademy') this.platformRedirect = 'https://devfolcademy.folclass.com/auth/login/platform';
            else this.platformRedirect = 'https://folcademy.folclass.com/auth/login/platform';
        }
        else this.platformRedirect = 'https://devfolcademy.folclass.com/auth/login/platform';
    }

    public onToggleSidenav = () => {
        this.sidenavToggle.emit();
    }

    sendId(id: string) {
        // // this._layoutService.sectionId = id;
        this.router.navigate(["/home", id])
        // console.log("se mandó", id)
        // this.homeComponent.scrollTo(id)
    }
}
