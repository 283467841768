<main class="bg-neutral color-primary">
  <section class="section-1">
    <div class="h-full w-full flex items-center justify-center">
      <div
        class="flex-col w-4/5 ct py-10 px-5 flex items-center justify-center bg-hero section-10"
      >
        <h4 class="title">
          Somos la academia de experiencias educativas de Folcode
        </h4>
        <p class="subtitle">
          SOMOS UNA COMUNIDAD <br />
          DE APRENDIZAJE CONTINUO
        </p>
      </div>
    </div>
  </section>
  <section class="py-4 lg:py-8 w-4/5 lg:w-full h-full mx-auto">
    <div class="lg:grid grid-cols-12 gap-4 py-8 md:py-16">
      <div class="col-span-5 font-bold text-2xl lg:text-8xl mb-3 lg:mb-0">
        <div class="lg:flex flex-col">
          <span class="lg:bg-neutral text-right pr-1 lg:pr-16">Nos</span>
          <span class="bg-accent lg:text-right px-2 lg:px-8">inspira</span>
        </div>
      </div>
      <div class="col-span-7">
        <div class="w-full lg:w-4/5">
          <p class="text-xs lg:text-2xl leading-4 lg:leading-10">
            Queremos formar talento en cualquier rincón del país, ponderando
            <strong>valores de abundancia, responsabilidad</strong>, pero por
            sobre todas las cosas crecimiento y mejora constante. Te invitamos a
            participar de esta experiencia única e innovadora.
          </p>
        </div>
      </div>
    </div>
    <div
      class="lg:w-4/5 lg:mx-auto flex justify-start lg:flex-none lg:grid lg:grid-cols-12 lg:gap-5"
    >
      <div class="lg:col-span-4"></div>
      <span
        class="lg:col-span-8 font-bold text-2xl lg:text-5xl leading-6 mb-3 lg:mb-6"
        >Nuestros principios</span
      >
    </div>
    <div
      class="lg:w-4/5 lg:mx-auto flex flex-row-reverse justify-between lg:flex-none lg:grid grid-cols-12 lg:gap-5"
    >
      <div
        class="lg:col-span-4 w-1/3 lg:w-auto flex justify-center items-center"
      >
        <img
          class="w-20 lg:w-52 h-40 lg:h-80"
          src="https://storefolclass.s3.amazonaws.com/public/landing/7c36187c-e933-4b33-9d9e-e6939fdda4a4.svg"
          alt=""
        />
      </div>
      <div class="lg:col-span-8 w-2/3 lg:w-auto">
        <div class="flex flex-col">
          <div class="flex flex-col mb-3 lg:mb-6 text-xs lg:text-2xl">
            <span class="font-bold">Creamos equipos</span>
            <span
              >Ninguno de nosotrxs es tan bueno solo como todxs nosotrxs
              juntxs.</span
            >
          </div>
          <div class="flex flex-col mb-3 lg:mb-6 text-xs lg:text-2xl">
            <span class="font-bold">Valoramos el proceso</span>
            <span
              >Cada intento, cada error y cada acierto es valioso para
              crecer.</span
            >
          </div>
          <div class="flex flex-col mb-3 lg:mb-6 text-xs lg:text-2xl">
            <span class="font-bold">Promovemos la creatividad</span>
            <span
              >Crear, modificar, combinar y expresarte con tecnología con la
              magía de los <span class="italic">qué tal sí.</span></span
            >
          </div>
          <div class="flex flex-col mb-3 lg:mb-6 text-xs lg:text-2xl">
            <span class="font-bold">Motivamos el aprendizaje</span>
            <span>Transformamos hacia aquello que queremos ser.</span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="py-8 w-4/5 mx-auto">
    <div class="w-full">
      <div class="mb-3 lg:mb-6">
        <span class="font-bold text-2xl lg:text-5xl leading-4"
          >Propuesta pedagógica</span
        >
      </div>
      <p class="text-xs lg:text-2xl leading-4 lg:leading-10">
        Nuestro objetivo es acompañarte a explorar y experimentar los roles de
        la industria tech de punta a punta y en comunidad. Contarás con espacios
        de intercambio y aprendizaje con tus compañeros; enfrentarás y
        resolverás con creatividad desafiantes actividades de gestión, diseño o
        desarrollo de productos digitales; y recibirás valoración de tu trabajo
        mediante feedbacks formativos.
      </p>
      <div class="mt-3 lg:mt-6 lg:mb-2">
        <span class="font-bold text-xs lg:text-2xl">Learning week</span>
      </div>
      <p class="text-xs lg:text-2xl leading-4 lg:leading-10">
        En Folcademy proponemos la semana de aprendizaje organizada en cinco
        tipos de experiencias: las Masterclass, el feedback en vivo, los
        workshops, el tiempo de trabajo personal y los círculos de aprendizaje.
        Sabemos que esta organización favorece el aprendizaje profundo basado en
        el feedback formativo y el trabajo colaborativo.
      </p>
      <div class="my-12 flex items-center justify-center">
        <carousel
          *ngIf="isMobile; else fullWeeks"
          [width]="carouselWidth"
          [height]="400"
          [cellWidth]="innerWidth"
          [cellsToShow]="1"
          [dots]="false"
          [arrowsOutside]="false"
        >
          <div
            class="carousel-cell flex items-center justify-center"
            *ngFor="let day of learningWeek"
          >
            <div class="flex flex-col items-center justify-start h-full">
              <div>
                <span class="flex items-start text-2xl">{{ day.title }}</span>
              </div>
              <div class="h-20 w-20 my-8">
                <img
                  [src]="day.img"
                  class="h-full w-full"
                  alt="imagen del día de la semana"
                />
              </div>
              <span class="text-xl text-center">{{ day.subTitle }}</span>
              <div class="w-40 mt-8">
                <p class="text-lg leading-5 h-full text-center">
                  {{ day.description }}
                </p>
              </div>
            </div>
          </div>
        </carousel>
      </div>
      <ng-template #fullWeeks>
        <div class="w-full flex justify-around items-center h-96 my-16">
          <div
            class="flex flex-col items-center justify-start h-full"
            *ngFor="let day of learningWeek"
          >
            <div>
              <span class="flex items-start text-2xl">{{ day.title }}</span>
            </div>
            <div class="h-20 w-20 my-8">
              <img
                [src]="day.img"
                class="h-full w-full"
                alt="imagen del día de la semana"
              />
            </div>
            <span class="text-xl text-center">{{ day.subTitle }}</span>
            <div class="w-40 text-ellipsis mt-8">
              <p class="text-lg leading-6 h-full text-center">
                {{ day.description }}
              </p>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="w-full flex justify-center">
        <a
          href="mailto:hi@folcademy.com?"
          target="_blank"
          class="w-max py-1 px-2 color-secondary-500 bg-primary font-bold text-xs lg:text-lg"
        >
        CONTÁCTANOS
        </a>
      </div>
    </div>
  </section> -->

  <!-- <section class="py-4 lg:py-8 w-4/5 mx-auto">
    <div class="mb-3 lg:mb-6">
      <span class="font-bold text-2xl lg:text-5xl leading-4"
        >Valoramos la singularidad</span
      >
    </div>
    <p class="text-xs lg:text-2xl leading-4 lg:leading-10">
      En Folcademy nos esforzamos por encontrar maneras creativas de reconocer
      el proceso formativo de las personas. Nos apasiona descubrir y acompañar
      el potencial de las personas. Por ello, desarrollamos un sistema de
      insignias que permiten reconocer y destacar aspectos relevantes en el
      desarrollo personal de nuestros estudiantes a lo largo de nuestros
      entrenamientos
    </p>
    <ul class="font-bold my-4 lg:my-8 text-xs lg:text-2xl">
      <li class="mb-1">
        Insignia Entrega ágil <span class="italic">-Agile delivery-</span>
      </li>
      <li class="mb-1">
        Insignia Colaboración <span class="italic">-Collaboration-</span>
      </li>
      <li class="mb-1">
        Insignia Crecimiento <span class="italic">-Grow Up-</span>
      </li>
      <li>Insignia Liderazgo <span class="italic">-Leadership-</span></li>
    </ul>
    <div class="flex mt-3 lg:mt-14">
      <div
        class="h-16 lg:h-32 w-16 lg:w-32 mr-4 lg:mr-10"
        *ngFor="let imgUrl of badges"
      >
        <img
          [src]="imgUrl"
          class="w-full h-full"
          alt="insignias de Folcademy"
        />
      </div>
    </div>
    <div class="w-full flex justify-center lg:justify-start my-6 lg:my-14">
      <a
        href="../../../../../assets/folcademy-insignias.pdf"
        target="_blank"
        class="w-max bg-primary px-2 py-1 font-bold color-neutral text-xs lg:text-lg"
      >
        DESCARGAR MÁS INFO
      </a>
    </div>
  </section> -->

  <section class="py-8 w-4/5 mx-auto">
    <div class="mb-3 lg:mb-6 text-center">
      <span class="font-bold text-2xl lg:text-5xl leading-4">Nosotros</span>
    </div>
    <div class="flex justify-center items-center">
      <carousel
        *ngIf="isMobile; else teamDesktop"
        [width]="carouselWidth"
        [height]="450"
        [cellWidth]="innerWidth"
        [cellsToShow]="1"
        [arrowsOutside]="true"
        [dots]="false"
      >
        <div
          class="carousel-cell flex items-center justify-center"
          *ngFor="let member of team"
        >
          <div
            class="flex flex-col items-center justify-evenly h-max text-center px-5 card"
          >
            <div class="card_image_border">
              <img
                class="card_image"
                [src]="member.img"
                alt="miembro de folcademy"
              />
            </div>
            <div>
              <span class="font-bold text-2xl">{{ member.name }}</span>
              <p class="text-base font-semibold">{{ member.rol }}</p>
            </div>
            <p class="text-xs txt2">
              {{ member.description }}
            </p>
          </div>
        </div>
      </carousel>
    </div>

    <ng-template #teamDesktop>
      <div class="flex justify-center items-center">
        <carousel
          [height]="460"
          [width]="carouselWidth"
          [cellsToShow]="cellsToShow"
          [cellWidth]="innerWidth"
          [dots]="false"
          [arrowsOutside]="true"
        >
          <div class="carousel-cell" *ngFor="let member of team">
            <div
              class="flex flex-col items-center justify-start h-max text-center px-5 lg:py-2 card"
            >
              <div class="card_image_border">
                <img
                  class="card_image"
                  [src]="member.img"
                  alt="miembro de folcademy"
                />
              </div>
              <div>
                <span class="font-bold text-2xl">{{ member.name }}</span>
                <p class="text-base font-semibold">{{ member.rol }}</p>
              </div>
              <p class="text-xs lg:text-sm lg:leading-4 txt2">
                {{ member.description }}
              </p>
            </div>
          </div>
        </carousel>
      </div>
    </ng-template>
  </section>
</main>
