<mat-toolbar class="header">
    <nav class="navbar">
        <a routerLink="/home">
            <img src="https://storefolclass.s3.amazonaws.com/public/landing/035f5855-7587-4461-810d-d76a00e4eb19.svg"
                class="logo" alt="logo folcademy">
        </a>
        <ul class="list-links">
            <!-- <li>
                <a (click)="sendId('trainings')" class="nav-link">Entrenamientos</a>
            </li> -->
            <li>
                <a routerLink="/home" class="nav-link">ENTRENAMIENTOS</a>
            </li>
            <li>
                <a routerLink="/empresa" class="nav-link">EMPRESAS</a>
            </li>
            <li>
                <a routerLink="/ONG" class="nav-link">ONG</a>
            </li>
            <!-- <li>
                    <a href="" class="nav-link">Sponsors </a>
                </li>-->
            <li>
                <a routerLink="/us" class="nav-link">NOSOTROS</a>
            </li>
        </ul>
        <div class="w-max flex items-center justify-end">
            <a href="https://folcademy.folclass.com/auth/login/platform" target="_blank"
                class="btn font-bold text-base py-1 px-1 md:mr-0">PLATAFORMA EDUCATIVA
            </a>
            <button mat-icon-button class="toggle" (click)="onToggleSidenav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" fill="#fff" class="bi bi-list"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
            </button>
        </div>
    </nav>
</mat-toolbar>