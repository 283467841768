import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class LayoutsService {

    sectionId: string = ''
    constructor() { }

    getLayouts$(): Observable<{}> {
        return of({});
    }

    getSectionId(id: string) { return this.sectionId = id; }

}
