import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-us',
  templateUrl: './us.component.html',
  styleUrls: ['./us.component.scss']
})
export class UsComponent implements OnInit {

  isMobile: boolean = false;
  learningWeek: Array<any> = [
    {
      title: "LUNES",
      img: "https://storefolclass.s3.amazonaws.com/public/landing/e58e50df-0901-481b-b065-dbd38970c82e.svg",
      subTitle: "MASTERCLASS",
      description: "Aprendizaje de técnicas, herramientas y agilidad junto al profesor para el desarrollo de productos digitales."
    },
    {
      title: "MARTES",
      img: "https://storefolclass.s3.amazonaws.com/public/landing/0199e405-175b-47ea-9148-1ba73d444bff.svg",
      subTitle: "FEEDBACK",
      description: "Espacio de aprendizaje y retroalimentación junto a tu mentor y compañeros."
    },
    {
      title: "MIÉRCOLES",
      img: "https://storefolclass.s3.amazonaws.com/public/landing/3c209d03-6984-46b1-a4de-2abd6f247d9b.svg",
      subTitle: "WORKSHOP",
      description: "Desarrollo en vivo del desafío, junto a tus compañeros y tu mentor."
    },
    {
      title: "JUEVES",
      img: "https://storefolclass.s3.amazonaws.com/public/landing/a1ff3530-8cdc-41bb-95c1-eecf5c68db52.svg",
      subTitle: "PERSONAL WORK",
      description: "Tiempo personal para el repaso de lo aprendido en la clase y el desarrollo del desafío."
    },
    {
      title: "VIERNES",
      img: "https://storefolclass.s3.amazonaws.com/public/landing/16b23129-da55-454d-9fea-d64a19c7edec.svg",
      subTitle: "PEER WORKING",
      description: "Espacio de reunión con tus compañeros para trabajar en los desafíos o profundizar lo aprendido."
    }
  ]
  badges: Array<any> = [
    "https://storefolclass.s3.amazonaws.com/public/landing/c3043cdc-0ce4-4f93-bfc0-31d236582629.svg",
    "https://storefolclass.s3.amazonaws.com/public/landing/30a23795-cb79-4d92-bc86-61ded82abbb4.svg",
    "https://storefolclass.s3.amazonaws.com/public/landing/c7b5d580-36f9-45a0-a9be-da1f2dfcd0c3.svg",
    "https://storefolclass.s3.amazonaws.com/public/landing/42aa472f-0748-4454-93c9-df0ea48a8c63.svg"
  ]
  team: Array<any> = [
    {
      img: './assets/People/us-02.png',
      name: 'Rochi',
      rol: 'Folcademy Leader',
      description: 'Me encargo de planificar, gestionar y ejecutar junto al equipo, los procesos y tareas necesarias para cumplir con el propósito y objetivos de Folcademy.'
    },
    {
      img: './assets/People/us-05.png',
      name: 'Lucre',
      rol: 'Folcademy Operations Analyst',
      description: 'Soy responsable de dirigir y ejecutar los entrenamientos por rol de Folcademy implementando mejoras e innovación para que esta sea tu mejor experiencia de aprendizaje.'
    },
    // {
    //   img: './assets/People/us-03.png',
    //   name: 'Vale',
    //   rol: 'Analista de marketing',
    //   description: 'Me encargo de coordinar y liderar al equipo de comunicación y performance para así diseñar estrategias de marketing efectivas que conecten alumnos con la academia.'
    // },
    {
      img: './assets/People/us-04.png',
      name: 'Claudio',
      rol: 'Folcademy Learning Specialist',
      description: 'Me encargo de coordinar y co-diseñar las experiencias de aprendizaje que proponemos en Folcademy. También estoy a cargo de la formación de profes y mentores.'
    },
    {
      img: './assets/People/us-06.png',
      name: 'Agus',
      rol: 'Folcamp Technical Lead',
      description: 'Me encargo de liderar el equipo de mentores de desarrolladores de los equipos de Folcamp garantizando la calidad de los productos digitales y sus especificaciones tecnológicas.'
    },
    {
      img: './assets/People/us-01.png',
      name: 'Michi',
      rol: 'Folcamp Project Manager',
      description: 'Soy la responsable de dirigir y ejecutar los entrenamientos por equipo junto al technical lead, asegurándome que los equipos desarrollen los proyectos para las ONG, aprendan y disfruten el camino.'
    }
  ]
  carouselWidth: number = 0;
  cellsToShow: number = 2;
  innerWidth: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.detectedScreen();
    this.checkWidthOwlItem();
  }

  detectedScreen() {
    if (screen.width <= 769) {
      this.carouselWidth = 350;
      this.isMobile = true;
    }
    if (screen.width > 770) {
      this.carouselWidth = 900;
      this.cellsToShow = 3;
      this.isMobile = false;
    }
  }

  checkWidthOwlItem() {
    if (window.innerWidth > 1200) {
      this.innerWidth = 600;
      this.carouselWidth = 950;
    }
    else if (window.innerWidth > 940) {
      this.innerWidth = (window.innerWidth / 3) - 20;
      this.carouselWidth = 850;
    }
    else if (window.innerWidth > 720) {
      this.innerWidth = (window.innerWidth / 2) - 25;
      this.carouselWidth = 550;
    }
    else if (window.innerWidth > 400) {
      this.innerWidth = (window.innerWidth) - 40;
      this.carouselWidth = 350;
    }
    else if (window.innerWidth > 0) {
      this.innerWidth = (window.innerWidth) - 40;
      this.carouselWidth = 250;
    }
  }
}
