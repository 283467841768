import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ong',
  templateUrl: './ong.component.html',
  styleUrls: ['./ong.component.scss']
})
export class OngComponent implements OnInit {

  dataExperience: Array<any> = [
    {
      number: '10',
      text: 'ONG DE ALTO IMPACTO FUERON PARTE DE FOLCAMP'
    },
    {
      number: '+200000',
      text: 'PERSONAS SE VIERON IMPACTADAS CON LOS PROYECTOS DE FOLCAMP'
    },
    {
      number: '60',
      text: 'JÓVENES SE ENTRENARON EN LOS ROLES MÁS DEMANDADOS DEL MUNDO TECH'
    },
    {
      number: '10',
      text: 'PROYECTOS DE TRIPLE IMPACTO FUERON DESARROLLADOS POR LOS EQUIPOS'
    },
    {
      number: '90%',
      text: 'RECOMENDARÍA LA EXPERIENCIA A OTROS JÓVENES DE SU EDAD'
    },
    {
      number: '80%',
      text: 'DE LOS PARTICIPANTES CONSIGUIERON EMPLEO EN MENOS DE 3 MESES'
    },
  ];
  proyectsONG: Array<any> = [
    {
      img: "/assets/ONG/Bisblink.png",
      text: 'El equipo que trabajó para esta ONG desarrolló una WebApp en la cual se pueden presentar los proyectos y recibir donaciones tanto de personas, como empresas, para financiar de manera económica un proyecto o para sumar voluntarios a los proyectos de Bisblick. Además, se desarrolló un ambiente para usuarios administradores, desde el cual se pueden crear, editar y eliminar proyectos. También crear nuevos usuarios administradores y llevar un listado ordenado de todos los donantes/ voluntarios que participan en los proyectos para que puedan realizar su posterior análisis al descargarlos en excel.',
      tag: '2022'
    },
    {
      img: "/assets/ONG/Mensajeros de la paz.png",
      text: 'El equipo encargado de esta ONG realizó una WebApp que permite observar mediciones de datos para dos tipos de usuarios: Usuario Director de ONG (Usuario Admin) y Usuario Director de Centro (Usuario Final), programar reportes digitales de los distintos centros y la carga automatizada de datos que reflejen información real.',
      tag: '2022'
    },
    {
      img: "/assets/ONG/SoF.png",
      text: 'Durante el FOLCAMP, el equipo asignado a Satellites on Fire desarrolló una WebApp para que los usuarios premium puedan ver datos estadísticos sobre los focos de incendio de las distintas zonas que monitorean en un período de tiempo determinado y acceder a distintos gráficos que permitan un mejor análisis. Además, tanto los gráficos como una tabla con todos los eventos pueden ser exportadas a PDF y en formato excel.',
      tag: '2022'
    },
    {
      img: "/assets/ONG/VocesVitales.png",
      text: 'El equipo encargado de esta ONG realizó una WebApp-responsive que permite la inscripción a programas facilitando la automatización de la carga de datos a Salesforce, la cual les permitirá a la ONG ahorrar tiempo y descomprimir la respuesta a los usuarios inscritos a programas. Además, incorporaron el sistema de notificaciones automático, mediante el cual mantienen a las personas inscritas al tanto sobre las novedades y los tiempos de los eventos.',
      tag: '2022'
    },
    {
      img: "/assets/ONG/Asela.png",
      text: 'El equipo que trabajó para esta ONG desarrolló una WebApp para dos tipos de usuarios: Equipo interno (Usuario Admin) y emprendedores que quieran involucrarse más en ASEA y socios (Usuario Final/Cliente). Mediante la WebApp, el usuario admin puede crear, editar y eliminar distintas landings, como así también crear, editar y eliminar membresías con la integración de Mercado Pago el cual se actualiza mensual y anualmente.',
      tag: '2022'
    },
    {
      img: "/assets/ONG/Hacerxtodos.png",
      text: 'El equipo que trabajó con esta ONG de incidencia internacional desarrolló una plataforma para dos tipos de usuarios: User emprendedor: puede registrarse y cargar todos los datos de su emprendimiento, puede modificarlos como también agregar nuevos puntos de venta y proveedores y User administrador: puede ver todos los emprendimientos cargados, ya sea por el mismo o por los user emprendedores. También puede completarlos, modificarlos o eliminarlos e incluso habilitar los emprendimientos para el público.',
      tag: '2021'
    },
    {
      img: "https://storefolclass.s3.amazonaws.com/public/landing/93d1265e-b545-4375-9609-74444bb627f0.png",
      text: 'El equipo encargado de esta ONG realizó un sistema de gestión de socios en el cual los donantes de TECHO pueden solicitar cambios personales como nombre, apellido, DNI, cambios de datos de contacto como teléfono, correo electrónico y cambios de medios de pago como así también de monto de donación.',
      tag: '2021'
    },
    {
      img: 'https://storefolclass.s3.amazonaws.com/public/landing/8eab29d2-fe36-41e1-aee4-6b50259b309e.png',
      text: 'El equipo asignado a SCHOLAS realizó un sistema de donaciones dónde cada persona registrada, pueda conocer sobre los proyectos de SCHOLAS ARGENTINA, interiorizarse sobre las causas y decidir a qué proyecto realizar su donación mediante Mercado Pago. El administrador lleva un seguimiento de la recaudación de cada proyecto mediante reportes.',
      tag: '2021'
    },
    {
      img: "https://storefolclass.s3.amazonaws.com/public/landing/f649ca88-eaeb-4927-b034-d0f35e7044b8.png",
      text: 'El equipo encargado de esta ONG realizó un sistema de gestión de voluntarios, el cual consiste en poder darle la autorización para realizar actividades en cualquier parte del País. También se realizó un formulario para que puedan formar parte del voluntariado de CARITAS.',
      tag: '2021'
    },
    {
      img: "https://storefolclass.s3.amazonaws.com/public/landing/fa0bc783-3520-47c9-b90a-c049581423bc.png",
      text: "El equipo que trabajó para esta ONG realizó un sistema digital de control de stock exclusivo para Banco de Alimentos que permite registrar, visualizar y gestionar los productos que reciben, clasifican, decomisan y donan. Su objetivo fue contribuir a la agilidad y digitalización del proceso.",
      tag: '2021'
    }
  ];
  // questions: Array<any> = [
  //   {
  //     questions: '¿Qué se tiene en cuenta para la selección de las ONG?',
  //     ansewer: ' Para la selección del Folcamp se tendrá en cuenta ONG, asociaciones, fundaciones o proyectos de impacto social con alcance nacional y/o internacional. La organización deberá completar el formulario de inscripción dispuesto para tal fin. Folcademy analizará las respuestas y seleccionará teniendo en cuenta la necesidad y alcance del proyecto.'
  //   },
  //   {
  //     questions: '¿Qué ofrecemos a las ONG?',
  //     ansewer: 'Un producto de tecnología mínimo viable (MVP) escalable y gratuito. Desarrollable en un período de cinco meses, a cargo de un equipo de tecnología junior liderado por un equipo de mentores senior sin costo.'
  //   },
  //   // {
  //   //   questions: '¿Qué ofrecemos a las ONG?',
  //   //   ansewer: 'Un producto mínimo viable de tecnología (MVP) escalable gratuito  desarrollable en un período de cuatro meses,  a cargo de un equipo de tecnología junior, liderado por un equipo de mentores senior sin costo.'
  //   // },
  // ]
  isMobile: boolean = false;
  carouselWidth: number = 0;
  cellsToShow: number = 2;
  innerWidth: number = 0;

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.detectedScreen();
    this.checkWidthOwlItem();
  }
  detectedScreen() {
    if (screen.width <= 769) return this.isMobile = true;
    if (screen.width > 770) return this.isMobile = false;
    else return this.isMobile = false;
  }
  // scrollTo(section: string) {
  //   document.querySelector('#' + section)
  //   ?.scrollIntoView(true);
  // }
  checkWidthOwlItem() {
    if (window.innerWidth > 1200) {
      this.innerWidth = 300;
      this.carouselWidth = 950;
    }
    else if (window.innerWidth > 940) {
      this.innerWidth = (window.innerWidth / 3) - 20;
      this.carouselWidth = 950;
    }
    else if (window.innerWidth > 720) {
      this.innerWidth = (window.innerWidth / 2) - 25;
      this.carouselWidth = 550;
    }
    else if (window.innerWidth > 400) {
      this.innerWidth = (window.innerWidth) - 40;
      this.carouselWidth = 350;
    }
    else if (window.innerWidth > 0) {
      this.innerWidth = (window.innerWidth) - 40;
      this.carouselWidth = 250;
    }
  }

}
