import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutsService } from 'src/app/core/layouts/services';
import { CourseService } from 'src/app/core/services/course.service';
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomeComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/animation.json',
  };

  options2:AnimationOptions = {
    path: '/assets/patineta.json',
  };




  imgProposal: Array<any> = [
    {
      src: "https://storefolclass.s3.amazonaws.com/public/landing/2a9590c6-d1cf-4abd-ad31-ed21fbcc391f.svg",
      text: "Formación 100% online"
    },
    {
      src: "https://storefolclass.s3.amazonaws.com/public/landing/800fecc2-321b-4938-99c6-021f6c06305d.svg",
      text: "Metodología Learning by Doing"
    },
    {
      src: "https://storefolclass.s3.amazonaws.com/public/landing/7568fdb9-3263-4b91-91f7-a642328dcde5.svg",
      text: "Mentalidad de crecimiento"
    },
    {
      src: "https://storefolclass.s3.amazonaws.com/public/landing/0053517d-6a80-4f06-9ab9-97a7f0be5c4d.svg",
      text: "Comunidad de aprendizaje continuo"
    },
    {
      src: "https://storefolclass.s3.amazonaws.com/public/landing/026f2df6-25ec-45ee-b5ad-a7e29eb70a72.svg",
      text: "Proyectos reales con impacto social"
    }
  ];
  dataFolcademy: Array<any> = [
    {
      number: 22,
      text: "PROFESORXS Y MENTORXS"
    },
    {
      number: 80,
      text: "HORAS DE ENTRENAMIENTO POR ROL"
    },
    {
      number: 320,
      text: "HORAS DE ENTRENAMIENTO POR EQUIPO"
    },
    {
      number: "+1000",
      text: "ALUMNXS SE FORMARON"
    },
    {
      number: "+70.000",
      text: "PERSONAS IMPACTADAS"
    }
  ];
  // dataTestimony: Array<any> = [
  //   'https://www.youtube.com/embed/9wakp4HVR4c',
  //   // 'https://www.youtube.com/embed/Kat-xRJo3ow'
  // ]
  // questions: Array<any> = [
  //   {
  //     questions: '¿Los entrenamientos son virtuales?',
  //     ansewer: 'Sí, los entrenamientos son 100% online.'
  //   },
  //   {
  //     questions: '¿Qué pasa si no puedo ver las clases en los horarios establecidos?',
  //     ansewer: 'Las masterclasses y workshops quedan grabados en la plataforma para que los veas luego. Están disponibles 24 horas luego del encuentro. No es obligatorio hacer los entrenamientos de manera sincrónica aunque sí recomendable. Tendrás acceso al material de tu entrenamiento por un año desde la fecha de finalización.',
  //   },
  //   {
  //     questions: '¿Qué pasa si no tengo los conocimientos básicos requeridos?',
  //     ansewer: 'Te recomendamos no inscribirte en los entrenamientos que no cumplas con los requisitos básicos Si no sabes con cual comenzar contáctanos para que te asesoremos.'
  //   },
  //   {
  //     questions: '¿Cuáles son los requisitos para obtener mi certificado?',
  //     ansewer: "El único requisito es el 100% de los desafíos aprobados. El plazo final de entrega de desafíos se te comunicará por mail y discord una vez comenzado el entrenamiento. Podrás descargar tu certificado una vez finalizado el entrenamiento."
  //   },
  //   {
  //     questions: '¿Qué sucede si quiero cambiarme de entrenamiento o me arrepiento de hacerlo?',
  //     ansewer: 'Podrás cambiarte de entrenamiento o solicitar la devolución de tu dinero según lo establecido en Términos y Condiciones.'
  //   },
  //   // {
  //   //   questions: '¿Cómo postulo mi ONG a Folcamp?',
  //   //   ansewer: 'Accedé a la sección ONG, conocé más de nuestro entrenamiento en equipo y completá el formulario de inscripción a la convocatoria. '
  //   // },
  // ]
  isMobile: boolean = false;
  training: any;
  imgResult: HTMLElement | undefined;
  isLoad!: boolean;
  section: string = '';

  constructor(
    private _courseService: CourseService,
    private _layoutService: LayoutsService,
    private _route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {
    this.section = this._route.snapshot.paramMap.get('section')!;
  }

  ngOnInit(): void {
    this.isLoad = true;
    this.getPublishedCourses();
    this.detectedScreen();
    this.getSection(this.section);
  }

 

  getSection(section: string) {
    if (section) { this.scrollTo(section) }
  }

  detectedScreen() {
    if (screen.width <= 769) return this.isMobile = true;
    if (screen.width > 770) return this.isMobile = false;
    else return this.isMobile = false;
  }

  getPublishedCourses() {
    this._courseService.getCourses({ status: 'publicado' }).subscribe(
      response => {
        this.training = response.data.filter((elem: any) => elem.active === 1)
        this.training.reverse();
        this.isLoad = false;
        this.cd.detectChanges()
      }
    ).add(
      this.cd.detectChanges()
    )
  }

  scrollTo(section: string) {
    document.querySelector('#' + section)
      ?.scrollIntoView(false);
  }
}
