import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  close: any

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit(this.close);
  }
  sendId(id: string) {
    // // this._layoutService.sectionId = id;
    this.router.navigate(["/home", id])
    // console.log("se mandó", id)
    // this.homeComponent.scrollTo(id)
}

}
function e(e: any) {
  throw new Error('Function not implemented.');
}

