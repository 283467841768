import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {

  @Input() question = '';
  @Input() ansewer = '';


  constructor() { }

  ngOnInit(): void {
  }

}
