import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'home',
    loadChildren: () => import('./core/routes/routes-routing.module').then((m) => m.RoutesRoutingModule)
  }
];

@NgModule({
  imports: [
      RouterModule.forRoot(routes, {
          scrollPositionRestoration: 'enabled',
          relativeLinkResolution: 'legacy',
      }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
