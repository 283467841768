<main class="main">
  <!-- Primera sección HERO -->

  <section class="section-1">
    <div class="h-full w-full flex items-center justify-center">
      <div
        class="flex-col w-4/5 ct py-10 px-5 flex items-center justify-center bg-hero section-10"
      >
        <h4 class="title">¿Sabías que la industria IT te espera?</h4>
        <p class="subtitle">
          FORMATE EN ROLES <br />
          DEL MUNDO TECH
        </p>
        <!-- <button mat-flat-button color="accent" class="btn" (click)="scrollTo('trainings')">VER
                    ENTRENAMIENTOS</button> -->
      </div>
    </div>
  </section>

  <!-- Cuarta Seccion TIPOS DE ENTRENAMIENTOS-->
  <section class="flex justify-center section-4 pt-10 md:py-16">
    <div class="lg:w-4/5 flex flex-col justify-around h-full">
      <div
        class="w-10/12 lg:w-full mx-auto lg:mx-0 text-xs md:text-base break-words h-2/6 md:h-2/6 flex flex-col items-center justify-center"
      >
        <!-- ENTRENAMIENTO POR ROL -->
        <span
          class="w-full title-section mb-4 text-2xl font-semibold md:text-5xl text-center"
          >Entrenate en un rol IT
        </span>
        <span class="lg:w-7/12">
          <p class="text-sm md:text-xl text-center color-primary-500">
            Entrenamientos 100% prácticos, online y en vivo
          </p>
        </span>
      </div>
      <div
        id="trainings"
        class="md:h-full h-full w-screen md:w-full my-4 md:my-8 mx-0"
      >
        <ng-container>
          <mat-spinner class="m-auto" *ngIf="isLoad"></mat-spinner>
        </ng-container>
        <ng-container>
          <div *ngIf="!isLoad">
            <carousel
              *ngIf="isMobile; else fullCards"
              [height]="330"
              [cellWidth]="'100%'"
              [cellsToShow]="1"
              [dots]="false"
              style="width: 100%; z-index: 0"
            >
              <div
                id="trainings"
                class="carousel-cell flex items-center justify-center"
                *ngFor="let card of training"
              >
                <mat-card class="card p-0 w-full">
                  <img
                    style="height: 40%; object-fit: cover"
                    class="w-full"
                    [src]="card.image_url"
                    alt="Portada del curso"
                  />
                  <mat-card-content class="p-3 w-full h-3/5">
                    <div class="flex h-full flex-col">
                      <h3 class="title-section">
                        {{ card.name }}
                      </h3>
                      <div class="flex items-center">
                        <img
                          class="object-cover mr-2 rounded-full"
                          style="width: 2rem; height: 2rem"
                          *ngIf="
                            card.instructor?.image_url || card.team?.imageUrl;
                            else noPic
                          "
                          [src]="
                            card.instructor?.image_url || card.team?.imageUrl
                          "
                        />
                        <ng-template #noPic>
                          <span
                            class="object-cover mr-1 rounded-full"
                            style="width: 2rem; height: 2rem"
                          >
                          </span>
                        </ng-template>
                        <span class="text-xs text-dark"
                          >Por {{ card.instructor?.name || card.team?.name }}
                          {{ card.instructor?.surname }}</span
                        >
                      </div>
                      <div class="flex h-2/4 justify-center items-end">
                        <a
                          href="https://folcademy.folclass.com/courses/info/{{
                            card.id
                          }}"
                          target="_blank"
                          class="bg-btn rounded-none font-bold color-neutral py-2 px-3 text-xs"
                          >VER MÁS</a
                        >
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </carousel>
            <ng-template #fullCards>
              <carousel
                [height]="330"
                [cellsToShow]="4"
                [arrowsOutside]="true"
                style="width: 100%"
              >
                <div
                  class="carousel-cell flex justify-center items-center py-10"
                  *ngFor="let card of training"
                  style="width: 360px"
                >
                  <a
                    href="https://folcademy.folclass.com/courses/info/{{
                      card.id
                    }}"
                    target="_blank"
                  >
                    <mat-card class="card w-full p-0">
                      <img
                        style="height: 40%; object-fit: cover"
                        class="w-full"
                        [src]="card.image_url"
                        alt="Portada del curso"
                      />
                      <mat-card-content class="px-5 py-3 w-full h-3/5">
                        <div class="flex flex-col justify-between h-full">
                          <div>
                            <h3 class="title-section text-xs">
                              {{ card.name }}
                            </h3>
                            <div class="flex items-center">
                              <img
                                class="object-cover mr-2 rounded-full"
                                style="width: 2rem; height: 2rem"
                                *ngIf="
                                  card.instructor?.image_url ||
                                    card.team?.imageUrl;
                                  else noPic
                                "
                                [src]="
                                  card.instructor?.image_url ||
                                  card.team?.imageUrl
                                "
                              />
                              <ng-template #noPic>
                                <span
                                  class="object-cover mr-1 rounded-full"
                                  style="width: 2rem; height: 2rem"
                                >
                                </span>
                              </ng-template>
                              <span class="text-xs text-dark"
                                >Por
                                {{ card.instructor?.name || card.team?.name }}
                                {{ card.instructor?.surname }}</span
                              >
                            </div>
                          </div>

                          <div class="flex h-2/4 justify-center items-end">
                            <a
                              class="bg-btn mb-3 rounded-none font-bold color-neutral py-2 px-3 text-xs"
                              >VER MÁS</a
                            >
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </a>
                </div>
              </carousel>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <!-- Segunda sección PROPUESTA-->
  <section class="flex justify-center section-2 section-4">
    <div class="lg:w-4/5 flex flex-col h-full pt-10 pb-4 md:py-16">
      <div
        class="w-10/12 lg:w-full mx-auto lg:mx-0 flex flex-col justify-center items-center"
      >
        <span
          class="title-section mb-4 text-2xl font-semibold md:text-5xl text-center"
          >Nuestra propuesta</span
        >
        <div class="lg:w-7/12">
          <p class="text-sm md:text-xl text-center color-primary-500">
            Formamos y potenciamos <strong>talentos</strong>, ofreciendo
            experiencias transformadoras de <strong>aprendizaje</strong> en la
            industria Tech, para conectarlos con
            <strong>oportunidades globales.</strong>
          </p>
        </div>
      </div>
      <div
        *ngIf="isMobile; else galery"
        class="h-96 flex mt-10 w-screen mx-0 justify-center"
      >
        <carousel
          [cellWidth]="'100%'"
          [cellsToShow]="1"
          [margin]="100"
          [dots]="false"
          [arrows]="true"
          style="width: 100%; height: 100%"
        >
          <div
            class="carousel-cell flex flex-col text-center"
            style="max-height: 500px"
            *ngFor="let img of imgProposal"
          >
            <img
              [src]="img.src"
              class="img-carousel flex items-center justify-center"
            />
            <span class="mt-5 normal-case font-semibold color-primary-500">{{
              img.text
            }}</span>
          </div>
        </carousel>
      </div>
      <ng-template #galery>
        <div class="flex items-center justify-between md:h-max mt-8 md:mt-16">
          <div
            class="flex flex-col justify-center items-center text-center px-2 h-80 max-w-56"
            *ngFor="let img of imgProposal"
          >
            <div class="h-3/4 w-36">
              <img class="h-max w-full object-contain" [src]="img.src" />
            </div>
            <div class="h-1/4">
              <span class="uppercase font-semibold color-primary-500">{{
                img.text
              }}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>

  <!-- Tercera Seccion CAMINO EN IT-->
  <section class="flex justify-center section-31 py-10 md:py-16">
    <div
      class="md:w-4/5 flex flex-col justify-center items-center md:m-0 h-full"
    >
      <div
        class="w-10/12 md:w-full mx-auto md:mx-0 mb-8 h-2/6 flex flex-col justify-center items-center"
      >
        <span
          class="title-section mb-2 md:mb-4 text-2xl font-semibold md:text-5xl text-center"
        >
          El camino que te proponemos</span
        >
        <!-- <div class="lg:w-10/12">
          <p class="text-sm md:text-xl text-center">
            Diseñamos entrenamientos ágiles y continuos, para que puedas
            desarrollar tus habilidades y formar parte de equipos de alto
            rendimiento
          </p>
        </div> -->
      </div>
      <div class="flex items-center justify-center my-4 section-ye">
        <div class="flex flex-col items-center justify-center w-2/4">
         
   <ng-lottie width="500px" [options]="options2"></ng-lottie>
        </div>
        <div class="h-full w-2/4 flex items-center">
          <div class="w-full flex flex-col items-center justify-center mb-8">
            <span class="font-bold color-primary-500 text-5xl"
              >Entrenamiento <br />
              por rol</span
            >
            <span class="text-light color-primary-500 mr-14 mt-6"
              >Capacitate en el rol que desees <br />
              eligiendo tu propio camino. Nuestros <br />
              entrenamientos están pensados para <br />
              complementarse entre sí.</span
            >
          </div>
        </div>
        

        

     

      </div>

      <div class="flex items-center justify-center my-4 section-purple">
        <div class="h-full w-2/4 flex items-center">
          <div class="w-full flex flex-col items-center justify-center mb-8">
            <span class="font-bold color-primary-500 text-5xl"
              >Entrenamiento <br />
              en equipo</span
            >
            <span class="text-light color-primary-500 mr-10 mt-6"
              >Participá de nuestro campamento de <br /> tecnología, luego de haber
              finalizado tu <br /> entrenamiento por rol, formando parte <br /> de un equipo
              real de trabajo IT.</span
            >
          </div>
        </div>
        <div class="flex flex-col items-center justify-center w-2/4">
          <ng-lottie width="500px" [options]="options"></ng-lottie>
        </div>
      </div>
    </div>
  </section>

  <section class="flex justify-center section-41 pt-10 md:py-16">
    <div class="lg:w-4/5 flex flex-col justify-around h-full">
      <div
        class="w-10/12 lg:w-full flex flex-col items-center justify-center mx-auto lg:mx-0 pt-8 md:py-8 text-xs md:text-base break-words h-1/6 md:h-1/6"
      >
        <span
          class="mb-2 md:mb-4 text-2xl font-semibold md:text-5xl text-center color-neutral"
          >Entrenate en equipo <br>
          <span class="font-semibold color-secondary-500"> Folcamp</span>
        </span>
        <div class="md:w-9/12">
          <p class="text-sm md:text-xl text-center color-neutral">
            Junto a otrxs jóvenes pondrás en práctica todos los conocimientos
            adquiridos <br> en los entrenamientos por rol, viviendo la experiencia
            única de ser parte de un <br> proyecto real de desarrollo de tecnología
            para una organización determinada. <br> ¡Viví tu primera experiencia real
            en el mundo tech!
          </p>
        </div>
      </div>

      <ng-container>
        <div
          *ngIf="isMobile; else notCarousel"
          class="flex w-screen justify-center mx-0 h-80"
        >
          <!-- <carousel
            [cellWidth]="'100%'"
            [height]="330"
            [margin]="100"
            [dots]="false"
            style="width: 100% !important"
          > -->
          <div class="w-full flex flex-col items-center justify-center">
            <!-- <div class="w-full flex items-center justify-center">
                <div
                  class="w-2/4 flex flex-row items-center justify-center mb-5"
                >
                  <span class="font-bold color-primary-500 text-8xl mr-1"
                    >1</span
                  >
                  <span class="text-light color-primary-500"
                    >FORMAMOS UN EQUIPO DE TRABAJO ÁGIL</span
                  >
                </div>
              </div> -->
            <div class="w-9/12">
              <img
                class="w-full h-48"
                src="assets/FolcampIlustration.svg"
                alt=""
              />
              <p class="text-sm md:text-xl text-center color-neutral">
                Talento tech junior capacitado. <br />
                Producto digital para ONGs.
              </p>
            </div>
          </div>
          <!-- <div class="carousel-cell">
              <div class="flex flex-col items-center justify-center my-2">
                <div class="w-full flex items-center justify-center">
                  <div
                    class="w-2/4 flex flex-row items-center justify-center mb-5"
                  >
                    <span class="font-bold color-primary-500 text-8xl mr-1"
                      >2</span
                    >
                    <span class="text-light color-primary-500"
                      >ACTIVAMOS <br />
                      NUESTRO <br />
                      PROCESO</span
                    >
                  </div>
                </div>
                <div class="w-9/12 h-44">
                  <img
                    class=""
                    src="https://storefolclass.s3.amazonaws.com/public/landing/fbdc6530-6ffc-4949-8d8a-fc83ae766fe5.svg"
                    alt=""
                  />
                </div>
              </div>
            </div> -->
          <!-- <div class="carousel-cell">
              <div class="flex flex-col items-center justify-center my-2">
                <div class="w-full flex items-center justify-center">
                  <div
                    class="w-2/4 flex flex-row items-center justify-center mb-5"
                  >
                    <span class="font-bold color-primary-500 text-8xl mr-1"
                      >3</span
                    >
                    <span class="text-light color-primary-500"
                      >GENERAMOS <br />
                      IMPACTO</span
                    >
                  </div>
                </div>
                <div class="w-9/12 h-44">
                  <img class="" src="/assets/imagenFolcademy.svg" alt="" />
                </div>
              </div>
            </div> -->
          <!-- </carousel> -->
        </div>
        <ng-template #notCarousel>
          <div class="flex flex-col items-center">
            <div class="w-10/12 flex flex-col justify-center">
              <div class="flex items-center justify-center my-4">
                <!-- <div class="h-full w-1/4 flex items-center">
                  <div
                    class="w-full flex flex-row items-center justify-center mb-8"
                  >
                    <span class="font-bold color-primary-500 text-8xl mr-2"
                      >1</span
                    >
                    <span class="text-light color-primary-500"
                      >FORMAMOS UN <br />
                      EQUIPO DE <br />
                      TRABAJO ÁGIL</span
                    >
                  </div>
                </div> -->
                <div class="w-3/4">
                  <img
                    class="w-full h-96"
                    src="assets/FolcampIlustration.svg"
                    alt=""
                  />
                  <p class="text-sm md:text-xl text-center color-neutral pt-6">
                    Talento tech junior capacitado. <br />
                    Producto digital para ONGs.
                  </p>
                </div>
              </div>
              <!--<div class="flex items-center justify-center my-2">
                 <div class="h-full w-1/4 flex items-center">
                  <div class="w-full flex flex-row items-center justify-center">
                    <span class="font-bold color-primary-500 text-8xl mr-2"
                      >2</span
                    >
                    <span class="text-light color-primary-500"
                      >ACTIVAMOS <br />
                      NUESTRO <br />
                      PROCESO</span
                    >
                  </div>
                </div>
                <div class="w-3/4">
                  <img
                    class="w-full h-80"
                    src="https://storefolclass.s3.amazonaws.com/public/landing/fbdc6530-6ffc-4949-8d8a-fc83ae766fe5.svg"
                    alt=""
                  />
                </div>
              </div> -->
              <!-- <div class="flex items-center justify-center my-2">
                <div class="h-full w-1/4 flex items-center">
                  <div class="w-full flex flex-row items-center justify-center">
                    <span class="font-bold color-primary-500 text-8xl mr-2"
                      >3</span
                    >
                    <span class="text-light color-primary-500"
                      >GENERAMOS <br />
                      IMPACTO</span
                    >
                  </div>
                </div> 
                 <div class="w-3/4">
                  <img
                    class="w-full h-80"
                    src="/assets/imagenFolcademy.svg"
                    alt=""
                  />
                </div>
              </div> -->
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </section>

  <!-- Quinta Sección FOLCADEMY-->
  <section class="flex justify-center section-5 pt-10 md:py-16">
    <div class="lg:w-4/5 flex flex-col md:m-0 h-full">
      <div
        class="flex flex-col justify-center items-center md:mx-0 h-2/6 md:h-2/5"
      >
        <!-- NUMEROS FOLCADEMY -->
        <div
          class="w-full mx-auto lg:mx-0 text-xs md:text-base break-words6 flex justify-center"
        >
          <span class="title-section font-semibold text-2xl md:text-5xl"
            >Folcademy en números</span
          >
        </div>
        <div
          *ngIf="isMobile; else galeryNumbers"
          class="h-52 flex w-screen mx-0 justify-center"
        >
          <carousel
            [cellWidth]="'100%'"
            [cellsToShow]="1"
            [margin]="300"
            [dots]="true"
            [arrows]="false"
            style="width: 100%; height: 100%"
          >
            <div
              class="carousel-cell flex items-center justify-center flex-col h-full w-3/5"
              style="max-height: 500px"
              *ngFor="let info of dataFolcademy"
            >
              <div class="text-4xl color-primary font-bold">
                {{ info.number }}
              </div>
              <span
                class="text-2xl w-3/5 font-light color-primary text-center mb-8"
                >{{ info.text }}</span
              >
            </div>
          </carousel>
        </div>
        <ng-template #galeryNumbers>
          <div class="flex items-center h-52 w-full">
            <div
              class="flex items-center mx-2 flex-col h-full w-full"
              *ngFor="let info of dataFolcademy"
            >
              <div
                class="md:text-3xl lg:text-4xl h-full flex items-end mb-2 color-primary font-bold"
              >
                {{ info.number }}
              </div>
              <span
                class="md:text-1xl lg:text-2xl h-full flex items-start font-light color-primary text-center"
                >{{ info.text }}
              </span>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- Testimonios de alumnos -->
      <div class="w-full h-full md:h-4/6">
        <div class="w-full mx-auto lg:mx-0 pt-10 md:py-16 flex justify-center">
          <span
            class="text-center mb-0 text-2xl title-section font-semibold md:text-5xl"
            >Testimonios de alumnxs
          </span>
        </div>
        <div
          *ngIf="isMobile; else galeryTestimony"
          class="h-max w-screen mx-0 flex justify-center"
        >
          <carousel
            [cellWidth]="'100%'"
            [cellsToShow]="1"
            [margin]="100"
            [arrows]="true"
            style="width: 100%; height: 600px"
          >
            <div
              class="carousel-cell flex items-center justify-center flex-col h-max w-3/5"
              style="max-height: 600px"
            >
              <div class="drop-shadow-md">
                <iframe
                  width="250px"
                  height="500px"
                  src="https://www.youtube.com/embed/9wakp4HVR4c"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              class="carousel-cell flex items-center justify-center flex-col h-max w-3/5"
              style="max-height: 600px"
            >
              <div class="drop-shadow-md">
                <iframe
                  width="250px"
                  height="500px"
                  src="https://www.youtube.com/embed/Kat-xRJo3ow"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </carousel>
        </div>
        <ng-template #galeryTestimony>
          <div class="flex items-center justify-center h-5/6 w-full">
            <div class="mx-5 drop-shadow-md">
              <iframe
                width="250px"
                height="450px"
                src="https://www.youtube.com/embed/9wakp4HVR4c"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="mx-5 drop-shadow-md">
              <iframe
                width="250px"
                height="450px"
                src="https://www.youtube.com/embed/Kat-xRJo3ow"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </section>

  <!-- Confiaron en nosotros-->
  <section class="flex justify-center section-2 section-4">
    <div class="lg:w-4/5 flex flex-col h-full pt-10 pb-4 md:py-16">
      <div
        class="w-10/12 lg:w-full mx-auto lg:mx-0 flex flex-col justify-center items-center"
      >
        <span
          class="title-section mb-4 text-2xl font-semibold md:text-5xl text-center pb"
          >Confiaron en nosotros</span
        >
        <div class="slider">
          <div class="slide-track">
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-02.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados_Mesa de trabajo 1.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-03.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-06.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-08.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide mt_logo">
              <img
                src="/assets/Confiaron_Nosotros/Logo_aliados-12.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-10.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-09.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-11.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-04.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-05.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
            <div class="slide">
              <img
                src="/assets/Confiaron_Nosotros/Logo aliados-07.png"
                height="50"
                width="150"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Seccion de preguntas frecuentes -->
  <section class="flex justify-center py-10 h-max section-6">
    <div class="md:w-4/5 w-4/5 h-full">
      <div class="mx-auto">
        <span class="color-neutral text-2xl font-semibold"
          >Preguntas frecuentes</span
        >
      </div>
      <div class="my-3">
        <app-frequent-questions></app-frequent-questions>
      </div>
    </div>
  </section>
</main>
