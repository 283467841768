import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OngComponent } from './components/ong/ong.component';
import { UsComponent } from './components/us/us.component';
import { TycComponent } from './components/tyc/tyc.component';
import { EmpresaComponent } from './components/empresa/empresa.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home/:section',
    component: HomeComponent,
  },
  {
    path: 'ONG',
    component: OngComponent
  },
  {
    path: 'us',
    component: UsComponent
  },
  {
    path: 'tyc',
    component: TycComponent
  },
  {
    path: 'empresa',
    component: EmpresaComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutesRoutingModule { }
