<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title>
        ¿Qué se tiene en cuenta para la selección de las ONG’s?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      Para la selección del Folcamp se tendrá en cuenta ONG´s, asociaciones,
      fundaciones o proyectos de impacto social con alcance nacional y/o
      internacional. La organización deberá completar el formulario de
      inscripción dispuesto para tal fin. Folcademy analizará las respuestas y
      seleccionará teniendo en cuenta la necesidad y alcance del proyecto.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header
      class="text-xs md:text-base font-medium text-black"
    >
      <mat-panel-title> ¿Qué ofrecemos a las ONG? </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="text-xs md:text-base font-medium color-primary-500">
      Un producto de tecnología mínimo viable (MVP) escalable y gratuito.
      Desarrollable en un período de cinco meses, a cargo de un equipo de
      tecnología junior liderado por un equipo de mentores senior sin costo.
    </p>
  </mat-expansion-panel>
</mat-accordion>
